import React from 'react';

import { HashRouter as  Router ,NavLink } from "react-router-dom";

import './HeaderButton.css';

//var classNames = require('classnames');

class HeaderButton extends React.PureComponent{

    constructor(props) {
        super(props);
        //this.state = {date: new Date()};
        this.state = {};
    }

    render() {


        /*


        let css2 = ''+this.props.btn_pos;
        var btnClass = classNames({
            'hdr_btn': true,
            css2 : true,
            'btn-over': !this.state.isPressed && this.state.isHovered
          });
        //*/

        var btnClass = "hdr_btn";
        btnClass += " hdr_btn_"+this.props.btn ;
        btnClass += " hdr_icon_"+this.props.btn ;

        var sltClass = " hdr_icon_"+this.props.btn+"_selected" ;

        return <NavLink 
          to={this.props.to} 
          className={btnClass}
          activeClassName={sltClass}
        ></NavLink>
        
    }

}

export default HeaderButton;