import React from 'react';

import './CompanyFYJ.css';

import imgCap from './img/cap.png';

import CompanyLocation from './CompanyLocation.js';

class CompanyFYJ extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.gtagPageview(this.props.location.pathname);
    }

    componentWillUnmount() {

    }

    render() {

      return <div className="CompanyFYJ" >

        <div >

            {/*}
            <p>
                <div className="s3line">会社概要</div>
            </p>
            */}
            <img src={imgCap} className="ComFYJ-Cap" alt="" />

            <table className="ComFYJ-table  debugFYJ2" >
                <tbody>
                    <tr>
                        <td className="ComFYJ_td_ttl" align="left" valign="top">会社名</td>
                        <td className="ComFYJ_td_ctt" align="left" valign="top">株式会社FUNYOURS JAPAN</td>
                    </tr>


                    <tr>
                        <td className="ComFYJ_td_ttl" align="left" valign="top">所在地</td>
                        <td className="ComFYJ_td_ctt ComFYJ_td_map" align="left" valign="top">
                            <CompanyLocation />
                            {/*東京都新宿区大久保2-1-8 プラザ新大樹ビル306号*/}
                        </td>
                    </tr>
                    <tr>
                        <td className="ComFYJ_td_ttl" align="left" valign="top">代表取締役</td>
                        <td className="ComFYJ_td_ctt" align="left" valign="top">洪　而立</td>
                    </tr>
                    <tr>
                        <td className="ComFYJ_td_ttl" align="left" valign="top">資本金</td>
                        <td className="ComFYJ_td_ctt" align="left" valign="top">6000万円</td>
                    </tr>
                    <tr>
                        <td className="ComFYJ_td_ttl" align="left" valign="top">設立</td>
                        <td className="ComFYJ_td_ctt" align="left" valign="top">2014年4月30日</td>
                    </tr>
                    <tr>
                        <td className="ComFYJ_td_ttl" align="left" valign="top">事業内容</td>
                        <td className="ComFYJ_td_ctt" align="left" valign="top">
                            オンラインゲームサービスのライセンス及びスマホアプリ、<br />
                            PCブラウザゲームの企画、開発、運用及び販売。ソーシャルゲームのデザイン制作。
                        </td>
                    </tr>

                </tbody>
            </table>


        </div>

      </div>
        
    }

}

export default CompanyFYJ;