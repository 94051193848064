import React from 'react';

import { HashRouter as Router ,Link } from "react-router-dom";

import './Newscap.css';

import * as news from "../../module/News.js";

class Newscap extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      let newIcon = <td className="News-td_icon  debugFYJ3" align="left" valign="top"></td>;
      if( news.isHideNewIcon(this.props.data) )
          newIcon = <td className="News-td_icon_hide  debugFYJ3" align="left" valign="top"></td>;

      return <tr className="News-tr  debugFYJ2" >
        <td className="News-td_date  debugFYJ3" align="left" valign="top">
          {this.props.data.startMoment.format("YYYY/MM/DD")}
        </td>

        {newIcon}
        
        <td className="News-td_cap  debugFYJ3" align="left" valign="top">
            <Link
                 className="Newscap-Title"
                 to={'/newsdetail/'+this.props.data.link_url}
            >
              {this.props.data.title}
            </Link>
        </td>
      </tr>

    }

}

export default Newscap;