import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import stateHandler from './lib/Redux/stateHandler';

const store = createStore(stateHandler, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

/*
console.log("document._SVR_LIST"); // X
console.log(document._SVR_LIST);
console.log("window._SVR_LIST"); // O
console.log(window._SVR_LIST);
//*/
window._SVR_LIST.ENV === "STAGE" && (window.gtagPageview = (path) => { console.log("pageview:", path); });

ReactDOM.render(
    <Provider store={store}>
      <App 
        foo="foo2" 
        _SVR_LIST={window._SVR_LIST} 
        _SVC_GAME={window._SVC_GAME} 
        _SVC_DESIGN={window._SVC_DESIGN} 
        _SVC_DEV={window._SVC_DEV} 
      />
    </Provider>
    ,
    document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
