import React from 'react';

import { connect } from 'react-redux';

import { HashRouter as Router ,Route ,Switch ,Redirect ,Link ,NavLink } from "react-router-dom";

import './TopNews.css';

import imgCap from './img/cap_top_news.png';

import NewscapTop from '../news/NewscapTop.js';

class TopNews extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
        this.tD = ["2019/08/01" ,"2019/08/02"];
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="TopNews  debugFYJ3">

        {/*<div className="TopNews-Cap"></div>*/}
        <img src={imgCap} className="TopNews-Cap" alt="top news cap" />

        <Link className="TopNews-Btn-More" to='/news'></Link>

        {
            this.props.newsList.map((el, index)=>
            {
                if( index < 8 )
                    return (
                      <NewscapTop 
                        key={index}
                        //date={this.tD[1]}
                        data={el}
                      />
                    )
                    
                return null;
            })
        }

      </div>

        
    }

}

const topStateToProps = state=>(
  {
      newsList : state.newsList,
      bannerList : state.bannerList,
      leftBanner : state.leftBanner,
      downloadOptions : state.downloadOptions,
  });
export default connect(topStateToProps)(TopNews);
//export default TopNews;