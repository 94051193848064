import React from 'react';

import { HashRouter as  Router ,Route ,Switch ,Redirect ,Link ,NavLink } from "react-router-dom";

import * as util from "../../module/Util.js";

import './TopPics.css';

import TopPicsBtn from './TopPicsBtn.js';

import topPic1 from './img/pic_fy_building.png';

//import topPic2 from './img/pic_wf1.png';

import topPic3 from './img/pic_ps5.png';

/*

import topPic4 from './img/fy_building.png';
*/

class TopPics extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }



    render() {

      util.debug("this.props.match.path");
      util.log(this.props.match.path);

      return     <div className="TopPics  debugFYJ"  >


      <Switch>
        <Route path={`${this.props.match.path}/pic1`} component={TopPic1} />
      {/*  <Route path={`${this.props.match.path}/pic2`} component={TopPic2} /> */}
        <Route path={`${this.props.match.path}/pic3`} component={TopPic3} />        
        <Route render={() => (
            <Redirect to={`${this.props.match.path}/pic1`}/>
        )}/>

        {/*
        <Route
            exact
            path={this.props.match.path}
            component={TopPic1} 
        />
        <Route  component={TopPic1} />   ///  default (!) may result some issue
        */}

      </Switch>

      <div  className="TopPics-Btns  debugFYJ2" >
        <ul  className="TopPics-Btns-ul">
          <li  className="TopPics-Btns-li">
            <TopPicsBtn to="pic1"  match={this.props.match}  />
          </li>
          <li  className="TopPics-Btns-li">
            {/*<NavLink to={`${this.props.match.url}/pic1`}></Link>*/}
            <TopPicsBtn to="pic3"  match={this.props.match}  />
          </li>

        </ul>
      </div>



    </div>
        
    }

}

function TopPic1() {
  return <img src={topPic1} className="TopPics-Pics" alt="" />
}
/*
function TopPic2() {
  return <img src={topPic2} className="TopPics-Pics" alt="" />
}
  */

function TopPic3() {
    return <img src={topPic3} className="TopPics-Pics" alt="" />
  }


export default TopPics;