/* eslint-disable prettier/prettier */

import * as util from "./Util.js";

var ServiceGame = {};
var ServiceDesign = {};
var ServiceDev = {};

function serviceGame() {
  if ("undefined" === ServiceGame)
    return null;
  else
    return ServiceGame;
}

function serviceDesign() {
  if ("undefined" === ServiceDesign)
    return null;
  else
    return ServiceDesign;
}

function serviceDev() {
    if ("undefined" === ServiceDev)
      return null;
    else
      return ServiceDev;
}

function init_game( svcGame ) {

    ServiceGame = svcGame;

    util.debug("ServiceFYJ init_game");
    util.log(svcGame);
}

function init_design( svcDes ) {

  ServiceDesign = svcDes;

  util.debug("ServiceFYJ init_design");
  util.log(svcDes);
}

function init_dev( svcDev ) {

    ServiceDev = svcDev;

    util.debug("ServiceFYJ init_dev");
    util.log(svcDev);
}

function devContextStyle( data ) {
  if( 'object' === typeof data.context_sty && null !== data.context_sty )
      return data.context_sty;

  return {};
}
function titleContextStyle( data ) {
  if( 'string' === typeof data.title_sty && null !== data.title_sty )
      return data.title_sty;

  return "";
}

export {serviceGame ,serviceDesign ,serviceDev ,init_game ,init_design ,init_dev ,devContextStyle,titleContextStyle };
