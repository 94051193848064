import React from 'react';

//import './LawShop.css';

class AboutFYJ extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.gtagPageview(this.props.location.pathname);
    }

    componentWillUnmount() {

    }

    render() {

      return <div className="" >
          TODO About..
      </div>
        
    }

}

export default AboutFYJ;