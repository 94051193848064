import React from 'react';

import { connect } from 'react-redux';

//import memoizeOne from "memoize-one";

import * as util from "../../module/Util.js";

import * as news from "../../module/News.js";

import './NewsFYJ.css';

import NewsPageBtns from './NewsPageBtns.js';

import Newscap from './Newscap.js';

class NewsFYJ extends React.Component{

    constructor(props) {
        super(props);

        util.debug("NewsFYJ Cons props.newsList");
        util.log(props.newsList);

        this.clickPageBtn = this.clickPageBtn.bind(this);

        this.temp = {
        }

        this.state = {
            showRowNum: 11, //25,
            totalPage: 0,
            nowPage: 1,
            pageCheck: {
              hasPre: true,
              hasNext: true,
              isLast: true
            },
            filteredList: [], // HCY 2019AUG14 : really render the page
            pageIds: [], // HCY 2019AUG14 : render the page buttons
        }

        //this.memoizedUpdateDisplay = memoizeOne(this.updateDisplay);

    }

    componentDidMount() {
        window.gtagPageview(this.props.location.pathname);

    }

    // Deprecated
    // https://reactjs.org/docs/react-component.html#unsafe_componentwillmount
    /*
    componentWillMount() {
        util.debug("NewsFYJ componentWillMount");
        util.debug("NewsFYJ componentWillMount props.newsList");
        util.log(this.props.newsList);

        this.updateDisplay( this.props );
    }
    */

    // Deprecated
    //https://reactjs.org/docs/react-component.html#unsafe_componentwillupdate
    /*
    componentWillUpdate() {
    }
    */

    componentWillUnmount() {
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        // Re-run the filter whenever the newsList array change.
        // Note we need to store newsListOld to detect changes.
        // and nowPageOld ..
        if ( nextProps.newsList !== nextState.newsListOld 
         ||  nextState.nowPage !== nextState.nowPageOld
        ) {
            var temp = {...nextState};
            news.filterListSet( nextProps ,temp );
    
            /*
            this.setState({
                filteredList: this.temp.filteredList,
                totalPage: this.temp.totalPage,
                pageCheck: this.temp.pageCheck,
                pageIds: this.temp.pageIds
            });
            */

          return {
            nowPageOld: nextState.nowPage,
            newsListOld: nextProps.newsList,

            filteredList: temp.filteredList,
            totalPage: temp.totalPage,
            pageCheck: temp.pageCheck,
            pageIds: temp.pageIds
          };
        }
        return null;
    }

    // X, not work
    /*
    componentDidUpdate(prevProps)
    {
        if( prevProps.newsList !== this.props.newsList )
        {
            util.debug("componentDidUpdate prevProps.newsList !== this.props.newsList ");

            this.updateDisplay( this.props.newsList );
        }
    }
    */

    // Deprecated
    // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
    /*
    componentWillReceiveProps(newProps)
    {
        if (newProps.newsList !== this.props.newsList)
        {
            this.updateDisplay( newProps );
        }
    }
    */

    ///////////////////////////////////

    // Re-run the filter whenever the list array or filter text changes:
    /*
    ilter = memoize(
        (list, filterText) => list.filter(item => item.text.includes(filterText))
    );
    */

    // Deprecated, maybe merged into getDerivedStateFromProps
    updateDisplay( props_ ) {
        this.filterListSet( props_ );
    }
    // Deprecated
    filterListSet( props_ ) {
        this.temp = {...this.state};
        news.filterListSet( props_ ,this.temp );

        this.setState({
            filteredList: this.temp.filteredList,
            totalPage: this.temp.totalPage,
            pageCheck: this.temp.pageCheck,
            pageIds: this.temp.pageIds
        });

        //this.checkPageShow()
    }

  
    setNewsPage(pageNum) {
        if( this.state.nowPage === pageNum )
          return;
  
        if (pageNum < 1 || pageNum > this.state.totalPage)
          return;

        this.setState(
            {nowPage : pageNum},
            ()=>{
                //this.updateDisplay(this.props); // HCY 2019AUG23 try replaced by getDerivedStateFromProps
            }
        );

    }


    clickPageBtn(pageNum) {

        util.debug("clickPageBtn");
        util.log(pageNum);
  
        if ( 0 === pageNum ) {
          return;
        }
  
        if ( -1 === pageNum ) {
          this.setNewsPage(this.state.nowPage - 1);
          return;
        }
  
        if ( 9999 === pageNum ) {
          this.setNewsPage(this.state.nowPage + 1);
          return;
        }
  
        this.setNewsPage(pageNum);
    }
  

    ///////////////////////////////////

    render() {

      //this.updateDisplay(this.props); // X it include setState.

      return <div className="NewsFYJ" >

        <div className="News-Cap" />

        <table className="News-table  debugFYJ2" >
            <tbody>

                {
                    this.state.filteredList.map((el, index)=>
                    {
                        return (
                            <Newscap
                                key={index}
                                data={el}
                            />
                        );
                    })
                }

                <tr className="" >
                    <td className="News-td_btns" align="center" valign="top" colSpan="3" >
                        {util.debug("NewsFYJ render PageBtns")}
                        {util.log(this.state.pageIds)}
                        <NewsPageBtns 
                            clickBtnDo={this.clickPageBtn}
                            pageIds={this.state.pageIds}
                            nowPage={this.state.nowPage}
                        />
                    </td>

                </tr>

            </tbody>
        </table>



        
      </div>
        
    }

}


const newsStateToProps = state=>(
    {
        newsList : state.newsList,
        //bannerList : state.bannerList,
        //leftBanner : state.leftBanner,
        //downloadOptions : state.downloadOptions,
    });
export default connect(newsStateToProps)(NewsFYJ);
