import React from 'react';

import { HashRouter as Router ,Route ,Switch ,Redirect ,Link ,NavLink } from "react-router-dom";

import './BodyFYJ.css';

import TopFYJ from '../top/TopFYJ.js';
import NewsFYJ from '../news/NewsFYJ.js';
import Newsdetail from '../newsdetail/Newsdetail.js';
import CompanyFYJ from '../company/CompanyFYJ.js';
import ServiceFYJ from '../service/ServiceFYJ.js';
import RecruitFYJ from '../recruit/RecruitFYJ.js';
import ContactFYJ from '../contact/ContactFYJ.js';
import ElderarkContract from '../elderarkcontract/ElderarkContract.js';

import AboutFYJ from '../about/AboutFYJ.js';

import Privacy from '../privacy/Privacy.js';
import Contract from '../contract/Contract.js';
import LawShop from '../law_shop/LawShop.js';

class BodyFYJ extends React.Component{

    render() {

      return <div className="BodyFYJ  debugFYJ3" >
        <div className="middle2  debugFYJ2">
          
          <Switch>

              <Route exact path="/" render={() => (
                  <Redirect to="/top"/>
              )}/>

              {/* Obs, too buggy ? yap .. (!) exact ..and "/" still buggy */}
              {/*<Route path="/" component={TopFYJ} />*/}
              {/* This, failed to use nested(kid) Route */}
              {/*<Route exact path="/" component={TopFYJ} />*/}
              <Route path="/top" component={TopFYJ} />

              <Route path="/news" component={NewsFYJ} />

              <Route path="/newsdetail/:newsId" component={Newsdetail} />

              <Route path="/company" component={CompanyFYJ} />

              <Route path="/service" component={ServiceFYJ} />

              <Route path="/recruit" component={RecruitFYJ} />

              <Route path="/contact" component={ContactFYJ} />

              <Route path="/about" component={AboutFYJ} />

              <Route path="/privacy" component={Privacy} />

              <Route path="/contract" component={Contract} />
              <Route path="/elderarkcontract" component={ElderarkContract} />
              <Route path="/law_shop" component={LawShop} />


          </Switch>

        </div>

      </div>
        
    }

}

export default BodyFYJ;