import React from 'react';

import qs from 'qs';

import * as svr from '../../module/SvrInfo.js';

import * as util from '../../module/Util.js';

import './ContactFYJ.css';

import ContactForm from './ContactForm.js';
import ContactConfirm from './ContactConfirm.js';
import ContactSent from './ContactSent.js';

class ContactFYJ extends React.Component{

    constructor(props) {
        super(props);

        this.getError = this.getError.bind(this);

        this.clickGoCfm = this.clickGoCfm.bind(this);
        this.clickGoForm = this.clickGoForm.bind(this);
        this.clickGoSend = this.clickGoSend.bind(this);

        this.state = {

            nowPage : 0, /* 0: Form 1: Confirm(Cfm) 2: Sent  */

            error_msg : "",

            data : {
                company_name : "",
                agent_name : "",
                tel : "",
                mail : "",
                mail_re : "",
                context : ""
            }
        };
    }

    componentDidMount() {
        window.gtagPageview(this.props.location.pathname);
    }

    componentWillUnmount() {

    }

    /////////////////////////////////

    clickGoCfm(data_)
    {
        //alert("ContactFYJ clickGoCfm "+ data_.company_name);

        this.setState({
            nowPage: 1,
            data: data_,
        })
    }

    clickGoForm(data_)
    {
        //alert("ContactFYJ clickGoForm "+ data_.company_name);
        this.setState({
            nowPage: 0,
            data: data_,
            error_msg: ""
        })
    }

    clickGoSend(data_)
    {
        //alert("ContactFYJ clickGoSend "+ data_.company_name);
        this.setState({
            error_msg: ""
        })

        //*/
        // (!) legacy POST query string way
        //https://github.com/axios/axios#browser
        const form = qs.stringify({...data_});
        /*/
        // (!) modern POST json way
        // (?) this method may get extra OPTION request..
        const form = {...data_};
        //*/

        svr.urlContact().post("" ,form)
        .then(response => {
            util.debug("ContactFYJ Send 1825");
            util.log(response.data);

            //*/
            // HCY 2019SEP25 final spec, error all as response code
            this.setState({
                nowPage: 2,
                data: data_,
            })
            /*/
            // May Ack error Msg
            if( "" === response.data )
            {
                this.setState({
                    nowPage: 2,
                    data: data_,
                })
            }
            else
            {
                this.setState({
                    error_msg: ""+response.data
                })
            }
            //*/

        })
        .catch(error => {

            util.error("ContactFYJ Send");
            util.logErr(error);

            if( 'undefined' == typeof error.response ) // axios bugs for non response
            {
                this.setState({
                    error_msg: "接続エラーです。しばらくしてからもう一度お試しください。"
                })
                return;
            }

            util.error(error.response.data);
            util.error(error.response.status); //
            util.error(error.response.headers);

            //*/
            let err_msg = "";
            switch( error.response.status )
            {
                case 400:
                    err_msg = "無効なメール";
                    break;
                default:
                    err_msg = "不明なエラー";
                    break;
            }
            this.setState({
                error_msg: ""+err_msg
            })
            /*/
            this.setState({
                error_msg: ""+error
            })
            //*/

        });



    }

    getError(error_)
    {
        this.setState({
            error_msg: error_
        })
    }

    /////////////////////////////////

    render() {

      let Body = ContactForm;
      let cP = null;
      let cN = this.clickGoCfm;
      if( 1 === this.state.nowPage )
      {
        Body = ContactConfirm;
        cP = this.clickGoForm;
        cN = this.clickGoSend;
      }
      else if( 2 === this.state.nowPage )
      {
        Body = ContactSent;
        cP = null;
        cN = null;
      }


      return <div className="ContactFYJ" >

        <div className="Contact-Cap" />

        <div className="Contact-Err  debugFYJ2" >{this.state.error_msg}</div>

        <Body data={this.state.data}  
        clickPrevDo={cP} clickNextDo={cN}  
        sendError={this.getError}
        />

        <div className="Contact-Err  debugFYJ2" >{this.state.error_msg}</div>

      </div>
        
    }

}

export default ContactFYJ;