import React from 'react';

import { HashRouter as  Router ,Route ,Link ,NavLink } from "react-router-dom";

import './ServiceTab.css';

class ServiceTab extends React.PureComponent{

    constructor(props) {
        super(props);
        this.foo = {};
    }

    render() {

        var btnClass = "svc_tab";
        btnClass += " svc_tab_"+this.props.btn ;
        btnClass += " svc_tab_icon_"+this.props.btn ;

        var sltClass = " svc_tab_icon_"+this.props.btn+"_selected" ;

        return <NavLink 
          to={this.props.to} 
          className={btnClass}
          activeClassName={sltClass}
        ></NavLink>
        
    }

}

export default ServiceTab;