/* eslint-disable prettier/prettier */

// Do substring with Full-width and Half-width checking
function substringFY(str ,start ,end ) {
    let r = 0;
    let ret = "";
  
    if( 'undefined' === typeof str )
      return "";
  
    //for (let i = 0; i < str.length; i++) {
    //for (let i = start; i < end; i++) {
    for( let i = 0; i < str.length; i++ ){
  
        /*
        if( i >= str.length )
        {
          console.log(i+"= i >= str.length ="+str.length+"   ret=" + ret );
          break;
        }
        //*/
  
        const c = str.charCodeAt(i);
  
        //console.log(c);
  
        // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
        // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
        if ( (c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
            r += 0.5;
            ret += str[i];
  
            //console.log( end+"=end  r += 0.5 , r="+r);
        } else {
            r += 1;
            ret += str[i];
  
            //console.log( end+"=end  r += 1 , r="+r);
        }
  
        if( r >= end )
        {
          //console.log(r+"= r > end ="+end+" ret=" + ret );
  
          break;
        }
    }
  
    return ret;
  }
  
  
  function shortenCap( cap ,max ) {

    // #DEBUG
    //cap = "零二三四五六七八九十壹二三四五六七八九十貳二三四五六七八九十参二三四五六七八九十肆二三四五六七八九十伍二三四五六七八九十陸二三四五六七八九十";
  
    //let s = cap.substring( 0 ,max );
    let s = substringFY( cap ,0 ,max );
    if( s !== cap )
      s += ".."; // '&hellip;';
  
    return s;
  }

// HCY 2019AUG22 Deprecated
/*
function format_date(start_date)
{
    return start_date.replace(/\./g ,"/"); //  /\./g  is RegExp
}
*/

function validateEmail (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// HCY 2019JUL02 Just a simple wrapper for easy hide 
// all test debug log..
function debug( msg ) {
  //  console.log("#DEBUG "+msg);
}
function log( obj ) {
  //  console.log( obj );
}
function error( msg ) {
    console.log("#ERROR "+msg);
}
function logErr( obj ) {
    console.log( obj );
}

export { shortenCap ,validateEmail ,debug ,log ,error ,logErr };
