import React from 'react';

import { HashRouter as Router ,Link } from "react-router-dom";

import * as svr from '../../module/SvrInfo.js';

import * as util from '../../module/Util.js';

import './TopBanner.css';

class TopBanner extends React.PureComponent{

    constructor(props) {
        super(props);

        let url = "";
        if( svr.isGlobalBackupURL() )
        {
            url = svr.url_banner();
        }
        else
        {
            let si = svr.serverInfo();
            url = si.url_banner;
        }
        let imgSrc = url + this.props.bannerData.link_url;

        this.state = {
            src: imgSrc,
            error_1st: true,
        };

        this.onErrorImg = this.onErrorImg.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    ////////////////////////////////////

    onErrorImg(ev) {

        util.debug("onErrorImg");

        // HCY 2019OCT03 only handle the error once. so clear the binding.
        ev.target.onerror = null;

        if( svr.isGlobalBackupURL() )
        {
            return;
        }

        //*/
        if( this.state.error_1st )
        {
            let si = svr.serverInfo();
            let url = si.backup_url_banner;
    
            let imgSrc = url + this.props.bannerData.link_url;

            this.setState(
                {
                    src: imgSrc,
                    error_1st : false
                }
            )
        }
        /*/

        // HCY 2019OCT03 Failed to re-render ?
        // Final just CSS error, anyway using above
        // setState way seems more formal so kept..

        let si = svr.serverInfo();
        let url = si.backup_url_banner;

        let imgSrc = url + this.props.bannerData.link_url;

        ev.target.src = imgSrc;
        //*/

    }

    ////////////////////////////////////

    render() {

        let btnClass = "TopBanner-Btn  debugFYJ  ";
 
        let btnSty = {};

        let imgTag = <img className="TopBanner-Img"
            src = {this.state.src}
            alt = ""
            onError={this.onErrorImg}
        />


        let bennerBody="";
        let newsId = parseInt(this.props.bannerData.target_id);
        if( Number.isInteger(newsId) )
        {

            bennerBody = <Link 

                className={btnClass}
                style={btnSty}

                to={'/newsdetail/'+newsId}
            >
                {imgTag}
            </Link>
        }
        else
        {
            bennerBody = <a

                className={btnClass}
                style={btnSty}

                target="_blank"
                rel="noopener noreferrer"

                href={this.props.bannerData.target_id}
            >
                {imgTag}
            </a>
        }



      return <div className="TopBanner  debugFYJ2" >
        {bennerBody}
      </div>
        
    }

}

export default TopBanner;