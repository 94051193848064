import React from 'react';

import './ServiceFYJ.css';

function ServiceCapDesign() {
  return (
    <div className="ServiceCapDesign">
    </div>
  );
}

export default ServiceCapDesign;
