import React from 'react';

import * as svc from "../../module/ServiceFYJ.js";

import './ServiceInfoDev.css';

class ServiceInfoDev extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

        /*
        let picDev = {
            backgroundImage:'url('+process.env.PUBLIC_URL+''+this.props.data.pic_dev+')'
        }
        */
        let srcDev = process.env.PUBLIC_URL + this.props.data.pic_dev;
        if( srcDev.startsWith('http') )
            srcDev = this.props.data.pic_dev;

        /*
        let picCom = {
            backgroundImage:'url('+process.env.PUBLIC_URL+''+this.props.data.pic_com+')'
        }
        */
        let divcom = <div />
        if( 'undefined' !== typeof this.props.data.pic_com )
        {
            let srccom = process.env.PUBLIC_URL + this.props.data.pic_com;
            divcom = <img
                className = "SID-Pic-Com  debugFYJ2"
                src={srccom}
                alt=""
            />
        }
        if( 'undefined' !== typeof this.props.data.txt_com )
        {
            divcom = <div
                className="SID-Txt-Com  debugFYJ2"
                dangerouslySetInnerHTML={{__html : this.props.data.txt_com}}
            />
        }

        return <span className="ServiceInfoDev  debugFYJ3" >

            <div className="SID-Body  debugFYJ">

                <div className="SID-Context  debugFYJ2"
                    style={svc.devContextStyle(this.props.data)}
                    dangerouslySetInnerHTML={{__html : this.props.data.context}}
                >
                </div>

                {/*<div className="SID-Pic-Dev  debugFYJ2" style={picDev} />*/}
                <img
                    className = "SID-Pic-Dev  debugFYJ3"
                    src={srcDev}
                    alt=""
                />

            </div>

            {/*<div className="SID-Pic-Com  debugFYJ2" style={picCom} />*/}
            {divcom}

        </span>

    }

}

export default ServiceInfoDev;