import React from 'react';

//import { HashRouter as NavLink } from "react-router-dom"; // Xxx
import { HashRouter as  Router ,Route ,Switch ,Link ,NavLink } from "react-router-dom";

import './TopPicsBtn.css';

class TopPicsBtn extends React.PureComponent{

    /*
    constructor(props) {
        super(props);
    }
    */

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div>
      <NavLink 
        to={`${this.props.match.url}/${this.props.to}`}
        className="TopPics-Btn"
        activeClassName="TopPics-Btn-Active"
        >
      </NavLink>
      </div>
        
    }

}

export default TopPicsBtn;