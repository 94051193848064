import React from 'react';

import * as util from "../../module/Util.js";

import * as news from "../../module/News.js";

import './NewsdetailHeader.css';

class NewsdetailHeader extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      let newIcon = <div className="NewsdetailHeader-Icon-News  debugFYJ3" />;
      if( news.isHideNewIcon(this.props.contentData) )
          newIcon = "";

      return <div className="NewsdetailHeader  debugFYJ2" >

        <div className="NewsdetailHeader-Title  debugFYJ3" >
            {util.shortenCap(this.props.contentData.title ,56)}
        </div>

        <div className="NewsdetailHeader-Date  debugFYJ2" >
            {newIcon}
            <div className="NewsdetailHeader-Date-Txt  debugFYJ" >
              {this.props.contentData.startMoment.format("YYYY.MM.DD")}
            </div>
        </div>


      </div>
        
    }

}

export default NewsdetailHeader;