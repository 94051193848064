import React from 'react';

import './CompanyLocation.css';

class CompanyLocation extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="ComLoc">

        <div className="ComLoc-Map">
            <iframe className="ComLoc-Map-iframe  debugFYJ2"
                title="google_map"  
                scrolling="no" 
                src="https://maps.google.co.jp/maps?f=q&amp;source=s_q&amp;hl=ja&amp;geocode=&amp;q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%96%B0%E5%AE%BF%E5%8C%BA%E5%A4%A7%E4%B9%85%E4%BF%9D%EF%BC%92%EF%BC%8D%EF%BC%91%EF%BC%8D%EF%BC%98+&amp;aq=&amp;sll=35.693971,139.703522&amp;sspn=0.089922,0.174923&amp;brcurrent=3,0x60188d20fadfd951:0x365e67d62f6391bf,0,0x60188d20fae76f41:0x7876990ef90fd2a6&amp;ie=UTF8&amp;hq=&amp;hnear=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%96%B0%E5%AE%BF%E5%8C%BA%E5%A4%A7%E4%B9%85%E4%BF%9D%EF%BC%92%E4%B8%81%E7%9B%AE%EF%BC%91%E2%88%92%EF%BC%98&amp;t=m&amp;z=14&amp;ll=35.701445,139.708476&amp;output=embed"
            >
            </iframe>
            <a className="ComLoc-Map-Link" target="_blank" rel="noopener noreferrer" 
            href="https://maps.google.co.jp/maps?f=q&amp;source=embed&amp;hl=ja&amp;geocode=&amp;q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%96%B0%E5%AE%BF%E5%8C%BA%E5%A4%A7%E4%B9%85%E4%BF%9D%EF%BC%92%EF%BC%8D%EF%BC%91%EF%BC%8D%EF%BC%98+&amp;aq=&amp;sll=35.693971,139.703522&amp;sspn=0.089922,0.174923&amp;brcurrent=3,0x60188d20fadfd951:0x365e67d62f6391bf,0,0x60188d20fae76f41:0x7876990ef90fd2a6&amp;ie=UTF8&amp;hq=&amp;hnear=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%96%B0%E5%AE%BF%E5%8C%BA%E5%A4%A7%E4%B9%85%E4%BF%9D%EF%BC%92%E4%B8%81%E7%9B%AE%EF%BC%91%E2%88%92%EF%BC%98&amp;t=m&amp;z=14&amp;ll=35.701445,139.708476">
            大きな地図で見る</a>
        </div>
        <div className="ComLoc-Cap"></div>
        <div className="ComLoc-Txt">
        〒169-0072 東京都新宿区大久保２－１−８プラザ新大樹ビル３０６
        <br/>
        ※副都心線/大江戸線東新宿駅から徒歩3分(B3出口、もしくは出口奥のエレベータから)
        <br />
        JR新大久保駅から徒歩10分(改札右側から) 
        </div>

        {/*}
        <table className="" width="90%" border="0" cellpadding="10" background-color="#F1DFDF">
            <tbody>

                <tr>
                </tr>


                <tr>
                    <td align="left" valign="top" >所在地</td>
                    <td align="left" valign="top">東京都新宿区大久保2-1-8 プラザ新大樹ビル306号</td>
                </tr>
                <tr>
                    <td align="left" valign="top" >近隣の駅</td>
                    <td align="left" valign="top">副都心線/大江戸線東新宿駅から徒歩3分(B1出口側のエレベータから)
                        <br /> JR新大久保駅から徒歩10分(改札右側から)
                    </td>
                </tr>

            </tbody>
        </table>
          */}

      </div>
      
    }

}

export default CompanyLocation;