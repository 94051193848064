import React from 'react';

import { HashRouter as Router ,Link } from "react-router-dom";
// HCY 2019AUG26 (!) Even Router is un-used, still need.  Otherwise Link failed to work..

import * as util from "../../module/Util.js";

import * as news from "../../module/News.js";

import newPic from './img/news_icon.png';
import nullPic from '../../assets/img/empty.png';

import './NewscapTop.css';


class NewscapTop extends React.Component{

  constructor(props) {
      super(props);
      this.state = {};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {

    let newIcon = <img src={newPic} className="NewscapTop-Pic-New" alt="news test" />;
    let newsCapLen = 24;
    if( news.isHideNewIcon(this.props.data) )
    {
      /*/
      newIcon = "";
      newsCapLen = 30;
      /*/
      newIcon = <img src={nullPic} className="NewscapTop-Pic-New" alt="news test" />; // With a hole, Still looks some odd
      //*/
    }


    return <div className="NewscapTop  debugFYJ2">

        <span  className="NewscapTop-Date" >
            { this.props.data.startMoment.format("YYYY/MM/DD") }
        </span>

        {/*<img src={newsPic1} className="NewscapTop-Pic1" alt="news test" />*/}
        {newIcon}
        
        <Link
          className="NewscapTop-Title"
          to={'/newsdetail/'+this.props.data.link_url}
        >
          { util.shortenCap(this.props.data.title ,newsCapLen) }
        </Link>

    </div>
      
  }

}






export default NewscapTop;
