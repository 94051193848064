import React from 'react';

//import Moment from 'moment';

import axios from 'axios';

//import { connect } from 'react-redux';

import * as svr from '../../module/SvrInfo.js';

import * as util from "../../module/Util.js";

import './RecruitJob.css';

function defaultContentData()
{
    return {
        title: 'データが存在しません !',
        //startMoment: Moment(new Date()), //Moment(new Date()).format('YYYY.MM.DD'),
        content: "募集データが存在しません"
    }
}

// HCY 2019OCT03 for duel loading if using backup URL
function loadingContentData()
{
    return {
        title: '読み込み中.',
        content: "読み込み中..."
    }
}

class RecruitJob extends React.PureComponent{

    constructor(props) {

        util.debug("RecruitJob  Cons props.recruitData.link_url=" + props.recruitData.link_url);

        super(props);
        this.state = {
            newsId : props.recruitData.link_url,
            contentData : loadingContentData()
        };

        this.loadContentData(this.state.newsId); // vital

    }

    /*
    componentDidUpdate(prevProps)
    {
        util.debug("RecruitJob  componentDidUpdate  this.state.newsId=" + this.state.newsId);

        let lastId = this.props.recruitData.link_url;

        if (lastId !== this.state.newsId)
        {
            util.debug( lastId + " = lastId !== this.state.newsId = " + this.state.newsId );

            this.setState(
                {newsId : lastId},
                ()=>{
                    this.loadContentData( this.state.newsId );
                }
            );
        }

    }
    */

    /////////////////////////////////

    loadContentData(newsid ,url_news_detail="") {

        util.debug("loadContentData RecruitJob START newsid=" + newsid + " url_news_detail=" + url_news_detail);
        //util.log(this.props.newsList);

        // == == ==
        let url_req = '/' + newsid + '.html?t=' + new Date().getTime();
        let url1 = url_news_detail + url_req;
        if( "" === url_news_detail )
        {
            if( svr.isGlobalBackupURL() )
            {
                url1 = svr.url_news_detail() + url_req;
            }
            else
            {
                let svrInfo = svr.serverInfo();
                url1 = svrInfo.url_news_detail + url_req;
            }

        }
        util.debug("loadContentData try get from "+url1);

        //var tmpContent = defaultContentData();
        var tmpContent =  loadingContentData();
        //var tmpContent = defContent; // #TEST
  
        axios
          .get(url1)
          .then(response => {

            util.debug("loadContentData RecruitJob OK");
            //util.log(response.data);
  
            var aRtn = response.data;

            tmpContent.content = aRtn

            util.log(tmpContent);

            this.setState({contentData :tmpContent});

          })
          .catch(error => {
            util.error("Newsdetail loadContentData");
            util.logErr(error);

            util.log(tmpContent);

            if( "" === url_news_detail )
            {
                let svrInfo = svr.serverInfo();
                this.loadContentData(newsid ,svrInfo.backup_url_news_detail);

                util.debug("Newsdetail loadContentData "+newsid+" again from "+svrInfo.backup_url_news_detail);
            }
            else
            {
                this.setState({
                    contentData :defaultContentData()
                });
            }

          });
  
        // == == ==
    }

    /////////////////////////////////

    render() {

      return <div className="RecruitJob  debugFYJ2" >

        <div className="RecruitJob-Cap  debugFYJ3">
            <div className="RecruitJob-Cap-Txt  debugFYJ"
                dangerouslySetInnerHTML={{__html : this.props.recruitData.title}}
            />
        </div>

        <div className="RecruitJob-Cap-Bar  debugFYJ2" />

        <div className='RecruitJob-Content  debugFYJ3' 
            dangerouslySetInnerHTML={{__html : this.state.contentData.content}}
        />

      </div>
        
    }

}

export default RecruitJob;