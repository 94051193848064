import React from 'react';

import { connect } from 'react-redux'

import { HashRouter as Router ,Link } from "react-router-dom";

import TopBanner from './TopBanner.js';

import * as svr from '../../module/SvrInfo.js';

import * as util from '../../module/Util.js';

import './TopBanners.css';

class TopBanners extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    ////////////////////////////////////

    ////////////////////////////////////

    render() {

      return <div className="TopBanners  debugFYJ3" >

        <div className="TopBanners-Cap" />

        <div className="TopBanners-Body  debugFYJ2">
        {
            this.props.bannerList.map((op, index)=>
            {

                return <TopBanner
                    key={index}
                    bannerData={op}
                />

            })
        }
        </div>

      </div>
        
    }

}
const topBannersStateToProps = state=>(
{
    bannerList : state.bannerList,
});
export default connect(topBannersStateToProps)(TopBanners);
