import React from 'react';

import ServiceTab from './ServiceTab.js';

import './ServiceTabs.css';

class ServiceTabs extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <nav className="ServiceTabs  debugFYJ2" >
          <ul  className="ServiceTabs-ul  debugFYJ3">
            <li  className="ServiceTabs-li">
              <ServiceTab btn="game" to={`${this.props.match.path}/game`} />
            </li>
            <li  className="ServiceTabs-li">
              <ServiceTab btn="design" to={`${this.props.match.path}/design`} />
            </li>
            <li  className="ServiceTabs-li">
              <ServiceTab btn="dev" to={`${this.props.match.path}/dev`} />
            </li>
          </ul>
      </nav>

        
    }

}

export default ServiceTabs;