import React from 'react';

import * as svr from '../../module/SvrInfo.js';

import './Contract.css';

import imgCap from './img/cap.png';

class Contract extends React.PureComponent{

  componentDidMount() {
    window.gtagPageview(this.props.location.pathname);

}

componentWillUnmount() {

}
  render() {

    let svrInfo = svr.serverInfo();
    let madeDate = "附則：本規約はXXXX年OO月YY日 制定(please set made_date_contract in svr_list.js)";
    if( null !== svrInfo && 'undefined' !== typeof svrInfo.made_date_contract )
        madeDate = svrInfo.made_date_contract;


    return <div className="Contract  debugFYJ" >

      <img src={imgCap} className="Contract-Cap" alt="" />

      <div className="Contract-Pic-Abs  debugFYJ2" />

      <div className="Contract-Txt-Abs  debugFYJ2">
        FUNYOURS JAPAN利用規約（以下「本規約」といいます）。は、株式会社FUNYOURS JAPAN（以下「当社」といいます）が提供するサービス（以下「本サービス」といいます)をご利用いただくために遵守いただく事項を定めたものです。
      </div>

      <hr />
      <br />

      <div  className="Contract-Body  debugFYJ3" > {/* TEXT BODY START */}

<span className="Contract-Txt-Cap  debugFYJ2">
第１条（定義）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.  「本サービス」とは、当社が提供する、PCおよびスマートフォン等の携帯端末にて利用可能な各種サービスをいいます。
</div>
<div className="Contract-Txt  debugFYJ3">
2.  「会員」とは、本規約に同意の上、所定の手続きに従って本サービスの会員登録を申請し、当社の承認を得て登録した方をいいます。
</div>
<div className="Contract-Txt  debugFYJ3">
3.  「利用者」とは、会員登録の有無にかかわらず、本サービスを利用するすべての方をいいます。
</div>
<div className="Contract-Txt  debugFYJ3">
4.  「会員情報」とは、会員が本サービスの会員登録の際に入力した住所、氏名、電話番号、メールアドレス、その他の情報をいいます。<br />
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第２条（本規約の範囲及び変更）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      本規約は、本サービスの利用に関し、第１条で定める会員に適用されるものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
2.      当社が別途個別のサービスにおいて利用規約等を定めた場合、当該個別のサービスに関しては、本規約とともに当該利用規約等が適用されるものとし、当該利用規約等の定めと本規約の定めが相違する場合には、当該利用規約等の定めが本規約の定めに優先して適用され、当該利用規約等の定めがない事項については、本規約の定めが適用されるものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
3.      当社は会員の事前の承諾を得ることなく、本規約を変更できるものとします。本規約の変更は、変更後の規約を（
<a href="https://www.funyours.co.jp/" className="Contract-Txt-Mail"  target="_blank"  rel="noopener noreferrer"  >https://www.funyours.co.jp/</a>
）上において告知するものとし、変更後の規約は、掲載された時から会員に適用されるものとします。
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第３条 （会員登録）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      利用者は、本規約の他、当社が定める各種規約の内容を承諾の上、当社の定める手続きを完了し、当社の承諾をも得て登録します。
</div>
<div className="Contract-Txt  debugFYJ3">
2.      暴力団その他これらに類する団体、組織（以下「反社会勢力」といいます）に現在関与している方は会員登録できません。
</div>
<div className="Contract-Txt  debugFYJ3">
3.      会員は、会員登録の際に登録する自身に関する情報（以下「会員情報」といいます）については、登録情報が正確でその内容に虚偽がないことを誓約するものとします。登録は本人自身が行うものとし、代理人による登録は認めないものとします。会員情報に変更が生じた場合には、速やかにその変更を当社に届け出るものとします。変更・修正を怠り、当社からの通知が到達しなかった場合等当該変更・修正登録がなされなかったことにより生じた損害について、当社は一切責任を負わないものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
4.      本サービスの利用に関して、会員が、当社に対して損害を与えた場合、当該会員は当社の被った損害を賠償するものとし、第三者（他の会員登録者を含む。以下同じ）に対して損害を与えた場合または第三者との間で紛争が生じた場合、当該会員は自己の費用と責任でこれらを解決するものとし、当社は一切責任を負わないものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
5.      会員は、自らの責任において会員情報を管理するものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
6.      会員は、本サービス利用資格を有する間、パスワードを第三者に利用させたり、貸与、譲渡、売買、質入等をすることはできないものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
7.      会員は、会員情報を第三者に利用させたり、貸与、譲渡、売買、質入等をすることはできないものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
8.      パスワード等の管理不十分、使用上の過誤、第三者の使用等による損害の責任は会員が負うものとし、当社は一切責任を負いません。
</div>
<div className="Contract-Txt  debugFYJ3">
9.      会員は、登録情報のパスワードを第三者に知られた場合、第三者に使用されるおそれのある場合には、直ちに当社にその旨連絡するとともに、当社の指示がある場合にはこれに従うものとします。
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第４条 （本サービス利用停止、登録抹消）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      当社は、会員が以下の項目いずれかに該当したと認められた場合、当該会員に事前の通知することなく本サービスの利用を停止するか、会員情報の削除、その他必要な処理を行うことができるものとします。なお、当社は、その理由を説明または開示しない場合があります。
<br />
◆  本規約または諸規定に違反した場合
<br />
◆  違法または不正な行為があった場合
<br />
◆  第７条に定める禁止行為を行った場合
<br />
◆  その他、会員として不適格であると当社が判断した場合
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第５条（個人情報保護）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      当社は、本サービスの利用等に伴い当社が取得する会員に関する情報を当社が別途定める「プライバシーポリシー」に従い、取り扱うものとします。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第６条（知的財産権）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      会員は、本サービスを通じて提供されるいかなる情報についても、会員が個々の使用に関して権利者の許諾を得ている範囲または個人の私的複製など、著作権法において認められる範囲を超えて使用をすることはできません。
</div>
<div className="Contract-Txt  debugFYJ3">
2.      会員が本項の規定に違反して問題が生じた場合、会員は自己の責任と費用においてかかる問題を解決するものとし、当社には、いかなる不利益または損害を与えないものとします。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第７条（禁止事項）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      利用者による本サービスの利用に際して、以下の行為を禁止します。
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  公序良俗に反する行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  法律・条例・その他当社が定めるガイドラインに反するような、わいせつ、暴力的、児童ポルノ又は児童虐待に相当する表現行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  侮蔑的、迫害的な表現など他人を不快にさせる行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  有害なプログラム・スクリプト等をアップロードする行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  第三者の知的財産権(特許権、実用新案権、意匠権、商標権、著作権、名誉権、肖像権、プライバシー権、パブリシティ権、その他法令上または契約上の権利)を侵害する行為。いわゆる二次創作作品の投稿も禁止とさせて頂きます。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  当社の許諾を得ない営業、宣伝、広告、勧誘、その他営利を目的とする行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  反社会的勢力に対する利益供与その他の協力行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  宗教活動または宗教団体への勧誘行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  他人の個人情報、登録情報などを、不正に収集、開示または提供する行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  自殺、集団自殺、自傷、違法薬物使用、脱法薬物使用等を勧誘・誘発・助長するような行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  性交及びわいせつな行為を目的とした出会い等を誘導する行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  当社の許諾を得ない売買行為、オークション行為、金銭支払やその他の類似行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  第三者による利用及び、他人の名義、その他会社等の組織名を名乗ること等による、なりすまし行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  当社が提供するソフトウェアの逆コンパイル、逆アセンブル、リバースエンジニアリング等、本ソフトウェアのソースコードを解析する行為
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  本サービスに関わる各情報の改竄、消去等、不正なアクセス行為、また、行おうと試みる行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  本サービスもしくは本ソフトウェアの不具合、障害、または脆弱性を利用する行為
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  本サービスの不具合や障害を不正な目的で利用すること、それを他者へ伝達する行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  マクロツールなどゲームに影響を及ぼすことができる、当社に認められていないユーティリティ（外部プログラム・ツール）の使用、作成および提供、またそれらの送信、支援、宣伝する行為。サーバエミュレーターの利用やユーティリティーの開発行為、もしくはその利用行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  本サービスのサイトまたは本サービス内での掲示、告知等に違反する行為
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  本サービス運営からの確認に応答し、協力要請に従って頂かない行為。または警告を受けたにも関わらず従わない行為。
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  その他、当社が不適切と判断する行為。
  </div>
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第８条（未成年者）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      会員が未成年者ある場合、当社は当該会員からの会員登録及び本サービスの利用について保留することができるものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
2.      会員が未成年者である場合、会員登録および本サービスの利用については法定代理人の同意を得るものとします。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第９条（免責事項）
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.      当社は、以下の項目に該当する場合、利用者の承諾なしに、本サービスの全部又は一部を一時中断、停止する場合があります。この場合において、利用者に損害や不利益が発生しても、当社はその賠償や不利益を一切負担しないものとします。
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  システムの保守、更新又は当社が緊急と判断した場合
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  天災、火災、停電等不可抗力によりサービス提供が困難な場合
  </div>
  <div className="Contract-Txt-Hisi  debugFYJ">
  ◆  上記の他不測の事態によりサービス提供が困難な場合
  </div>
</div>
<div className="Contract-Txt  debugFYJ3">
2.      当社は、利用者の承諾なしに、サービス内容の変更、追加、修正、又は中止をする場合があります。利用者が本サービスを利用できなかったことにより蒙った損害や不利益が発生しても、当社はその賠償や不利益等について、その理由の如何に関わらず、いかなる責任も負わないものとし、一切負担しないものとします。。
</div>
<div className="Contract-Txt  debugFYJ3">
3.      本サービスにおいて、利用者の提供する情報が第三者の権利（著作権、意匠権、特許権、実用新案権、商標権等の知的財産権、肖像権を含みますがこれらに限られません）を侵害し、当社が損害賠償を余儀なくされた場合には、当社は当該侵害の原因となる情報を提供した利用者に対し、その損害及びこれに関する一切の費用（訴訟費用、賠償金、弁護士費用を含みますがこれらに限られません）を請求できるものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
4.      利用者間、または利用者と第三者との間でトラブルが発生した場合は、当事者間で解決するものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
5.      何らかの事情により当社からのメール及びメールマガジンを受け取ることができず、それによって利用者に損害や不利益が発生しても、当社はその賠償や不利益を一切負担しないものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
6.      当社は、会員情報の内容に基づいてのみ事務を処理することにより免責されるものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
7.      当社は、本サービスの内容、ならびに本サービス利用者が本サービスを通じて入手した情報等について、その確実性、正確性、完全性、有用性などにつき、いかなる責任も負いません。
</div>
<div className="Contract-Txt  debugFYJ3">
8.      当社は、本サービスに関して会員、利用者同士、もしくはその他第三者との間で発生した一切のトラブルについて、関知しません。
</div>
<div className="Contract-Txt  debugFYJ3">
9.      利用者は、法律の範囲内で本サービスを利用します。本サービスの利用に関して利用者が日本、または外国の法律に抵触した場合、当社は一切の責任を負いません。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第１０条（準拠法及び管轄裁判所）
</span>
<br></br>
<div className="Contract-Txt  Contract-Txt-Rule10  debugFYJ3">
本規約の準拠法は日本法とします。また、本サービスまたは本規約に関連して訴訟の必要が生じた場合には東京地方裁判所を第一審専属的合意管轄裁判所とします。
</div>
<br></br>
<br></br>

<br></br>
<br></br>

<span className="Contract-Txt-Date  debugFYJ3">
{madeDate}
{/*附則：本規約は2019年8月15日 制定*/}
</span>
<br></br>
<br></br>
<br></br>
<br></br>

      </div>  {/* TEXT BODY END */}

    </div>
      
  }

}

export default Contract;