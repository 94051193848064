import React from 'react';

import * as util from "../../module/Util.js";

import './NewsPageBtns.css';

import NewsPageBtn from './NewsPageBtn.js';

//var self;

class NewsPageBtns extends React.Component{

    constructor(props) {
        super(props);

        util.debug("NewsPageBtns.Cons pageIds");
        util.log(this.props.pageIds);

        util.debug("NewsPageBtns.Cons nowPage");
        util.log(this.props.nowPage);

        //this.self = this; // X
        this.isPageSelected = this.isPageSelected.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //////////////////////////////////

    isPageSelected(idx) {
        if( idx === this.props.nowPage )
        //if( idx == this.self.props.nowPage )
          return true;
  
        return false;
    }

    //////////////////////////////////

    render() {

      return <div  className="NewsPage-Btns  debugFYJ" >

        <ul  className="NewsPage-Btns-ul  debugFYJ2">

        {util.debug("NewsPageBtns.render pageIds")}
        {util.log(this.props.pageIds)}
        {

            this.props.pageIds.map((el, index)=>
            {
                return (
                    <li  className="NewsPage-Btns-li  debugFYJ3" key={index} >
                        <NewsPageBtn 


                            idx={el}
                            clickBtnDo={this.props.clickBtnDo}
                            isSelected={this.isPageSelected}
                        />
                    </li>
                );
            })
        }

        </ul>
    </div>
        
    }

}

export default NewsPageBtns;