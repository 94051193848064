import React from 'react';

import * as svr from '../../module/SvrInfo.js';

import './Privacy.css';

import imgCap from './img/cap.png';

class Privacy extends React.PureComponent{

  componentDidMount() {
    window.gtagPageview(this.props.location.pathname);

}

componentWillUnmount() {

}
    render() {

      let svrInfo = svr.serverInfo();
      let madeDate = "YYYY年MM月DD日 制定(please set made_date_privacy in svr_list.js)";
      if( null !== svrInfo && 'undefined' !== typeof svrInfo.made_date_privacy )
          madeDate = svrInfo.made_date_privacy;

      return <div className="Privacy  debugFYJ" >

        <img src={imgCap} className="Privacy-Cap" alt="" />

        <span> {/* TEXT BODY START */}
<br></br>
<p className="Privacy-Txt-Abs">
株式会社FUNYOURS JAPAN（以下「当社」といいます）はお客様が当社の提供する各種サービスを利用するにあたって、当社に提供いただくお客様の個人情報（以下「本個人情報」といいます）の取扱いに関して、以下のプライバシーポリシー（以下「本ポリシー」といいます）を定めています。当社は以下の本ポリシーをもって本個人情報の守秘義務を尊守いたします。また、本人に対して利用目的を明らかにし、目的外利用をしません。
</p>

<hr></hr>
<br></br>

<span className="Privacy-Txt-Cap">
1. 適用範囲について
</span>
<br></br>
<span className="Privacy-Txt">
本方針は当社が提供するすべてのサービスに適用されます。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
2. 個人情報の取得について
</span>
<br></br>
<span className="Privacy-Txt">
ご登録いただいた本個人情報はすべて当社が提供するサービスでのみ利用し、法令に定める場合を除き、原則として予めご本人の同意を得ること無く無断で第三者に知らせることはありません。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
3. 個人情報の利用目的について
</span>
<br></br>
<span className="Privacy-Txt">
当社は収集した情報を以下の目的で利用いたします。<br></br>
・本人確認および当社サービス提供のため。<br></br>
・当社サービス利用料金の決済および請求のため。<br></br>
・マーケティングデータの調査、統計、分析、アンケート実施のため。<br></br>
・各種メールマガジンの配信のため。<br></br>
・お客様からのお問い合わせに対する対応のため。<br></br>
・規約違反行為の調査および対応など、個別対応が業務に必要である場合。<br></br>
・お客様へキャンペーン等の抽選及び賞品や商品発送のため。なお、商品発送にあたり、<br></br>
　お客様の個人情報を運送会社などに委託、提供いたします。<br></br>
・その他、当社の提供するサービスに関する重要なお知らせなど、必要に応じた連絡を行うため。<br></br>
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
4.保存期間
</span>
<br></br>
<span className="Privacy-Txt">
当社は、利用目的に必要な範囲で本ポリシーの保存期間を定め、保存期間経過後または
利用目的達成後は、遅滞なく本個人情報を適切な方法で消去、または破棄します。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
5. 個人情報の委託について
</span>
<br></br>
<span className="Privacy-Txt">
当社は、本個人情報の取り扱いの全部または一部を第三者に委託する場合は、当該第三者について厳正な調査を行い、取り扱いを委託された本個人情報の安全管理が図られるよう当該第三者に対する必要かつ適切な監督を行います。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
6. 個人情報の提供について
</span>
<br></br>
<span className="Privacy-Txt">
上記原則に関わらず、法令の定めにより開示が認められている場合に加え、以下の場合には当社は本個人情報を開示することがあります。<br></br>
・情報開示や共有について、お客様本人の同意がある場合<br></br>
・警察や裁判所、検察庁、弁護士会、消費者センターまたはこれらに準じた公的機関その他の団体・個人から照会<br></br>
　もしくは要請等を受けた場合<br></br>
・犯罪性のある行為が行われている可能性があると判断した場合<br></br>
・人の生命、身体および財産等に対する差し迫った危険があり、緊急の必要性がある場合<br></br>
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
7.クッキーの取り扱いについて
</span>
<br></br>
<span className="Privacy-Txt">
当社は、サイトの利用状況を調査やマーケティング、プロモーション等を実施するために<br></br>
クッキー、その他の類似技術を用いることがあります。<br></br>
クッキーとは、お客様がウェブサイトを訪問した際の利用履歴などを保存する技術です。<br></br>
クッキーは、お客様がご利用のブラウザの機能により、すべてのクッキーを許可する、すべてのクッキーを削除する、クッキーを拒否する等の設定を選択することができます。<br></br>
クッキーに関する設定方法は、ご使用になられているブラウザにより異なります。<br></br>
クッキーを削除、拒否された場合、当社または第三者が提供する認証が必要なサービスを受けられなくなる等、各種サービスの利用において制約を受ける場合があります。<br></br>
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
8. 個人情報の保護に関する法令等の遵守
</span>
<br></br>
<span className="Privacy-Txt">
当社は、本個人情報の保護に関する法令や、行政機関の定める指針を遵守します。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
9. 本方針の改定について
</span>
<br></br>
<span className="Privacy-Txt">
当社は、本ポリシーを随時改定できるものとします。<br></br>
改定後の本ポリシーは、サイト上に掲載され、当該掲示がなされた時点から効力を生じるものとします。<br></br>
本ポリシー改定後も当社が提供するサービスを利用している場合、利用者は本ポリシーの改定に同意したものとみなされます。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Cap">
10. 個人情報に関するお問い合わせについて
</span>
<br></br>
<span className="Privacy-Txt">
当社が取扱う本ポリシーや本個人情報についての取扱いについてのお問い合わせは<br></br>
<a href="mailto:info@funyours.co.jp" className="Privacy-Txt-Mail">info@funyours.co.jp</a>宛にご連絡ください。
</span>
<br></br>
<br></br>

<span className="Privacy-Txt-Date">
{madeDate}
{/*2018年9月13日 制定*/}
</span>
<br></br>
<br></br>
<br></br>
<br></br>

        </span>  {/* TEXT BODY END */}

      </div>
        
    }

}

export default Privacy;