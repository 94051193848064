import React from 'react';

import './ServiceFYJ.css';

function ServiceCapDev() {
  return (
    <div className="ServiceCapDev">
    </div>
  );
}

export default ServiceCapDev;
