import React from 'react';

//import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import HeaderButton from './HeaderButton.js';

//import logo from './img/logo.png';

import './HeaderFYJ.css';
//import HeaderMenu from './HeaderMenu.js';
//import useModal from './HeaderMenuDialog.js';

function HeaderFYJ(props) {
//  const [Modal, open, close] = useModal();
 

  return (
    <div className="HeaderFYJ  debugFYJ">
      <nav>
        <HeaderButton btn="top" to="/top" />
        
        <HeaderButton btn="news" to="/news" />

        <HeaderButton btn="company" to="/company" />

        <HeaderButton btn="service" to="/service" />

        <HeaderButton btn="recruit" to="/recruit" />

        <HeaderButton btn="contact" to="/contact" />
        {/*
        <HeaderMenu  btn="menu"  onClick={open}/>
        <Modal>
        <div className='styles.mask'>
          <h1>Title</h1>
          <p>This is a customizable modal.</p>
          <button onClick={close}>CLOSE</button>
        </div>
      </Modal>        
      */}
      </nav>
    </div>

  );
}

export default HeaderFYJ;
