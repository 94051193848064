import React from 'react';

import { connect } from 'react-redux';

import * as stateHandlerClass from './lib/Redux/stateHandler';

import $ from 'jquery';

import axios from 'axios';

import Moment from 'moment';

import { HashRouter as Router ,Link } from "react-router-dom";
// HCY 2019AUG06 BrowserRouter is buggy.

import * as svr from './module/SvrInfo.js';
import * as svc from './module/ServiceFYJ.js';

import * as util from "./module/Util.js";

//import logo from './logo.png';

import './App.css';

import HeaderFYJ from './component/header/HeaderFYJ.js';
import HeaderBar from './component/header/HeaderBar.js';

import BodyFYJ from './component/body/BodyFYJ.js';

import FooterFYJ from './component/footer/FooterFYJ.js';

class App extends React.Component{

  constructor(props) {
    super(props);

    svr.init(1 ,props._SVR_LIST);
    svc.init_game(props._SVC_GAME);
    svc.init_design(props._SVC_DESIGN);
    svc.init_dev(props._SVC_DEV);

    util.debug("App cons foo="+props.foo);
  };

  componentDidMount() 
  {
    // TODO
    //document.title = "";

    this.svrInfo = svr.serverInfo();
    //let url1 = "" + this.svrInfo.api_all;
    let url1 = this.svrInfo.url_news + this.svrInfo.api_all;
    
    let url2 = "" + this.svrInfo.backup_url_news_api_all;

    this.loadNews(url1 ,url2);

  }

  /////////////////////////////////

  loadNews(url1 ,url2="") {

    util.debug("App loadNews "+url1+" , "+url2 );

    //svr.urlNews().get(url1) // HCY 2019OCT02 Obsolete, less flexible
    axios.get(url1)
    .then(response => {
      util.debug("App loadNewsList then");
      util.log(response.data);

      var aRtn = response.data;

      var usedId = {};
      var upNews = {};

      // News List
      if (aRtn.list !== undefined) {
        upNews = aRtn.list.filter(function(el) {
          //el.start_date = Moment(
          el.startMoment = Moment(
            new Date(el.start_date.replace(new RegExp(/-/gm), "/"))
          ); //.format("YYYY/MM/DD");
          // HCY 2019AUG22 the format may varied by case, try store Moment obj instead

          if (typeof usedId[el.link_url] === "undefined") {
            usedId[el.link_url] = true;
            return el;
          }

          return false;
        });

        this.props.dispatch({type : stateHandlerClass.UPDATE_NEWS_LIST, newsList : upNews})

        util.debug("App loadNewsLists");
        util.log(upNews);
      }

      // Banner List
      if (aRtn.banner !== undefined) {

        let leftBannerData = {};
        let rightBannerDataS = aRtn.banner.filter(function(element)
        {
          if (element.cate_name === "sidebanner")
            return element;
          if (element.cate_name === "mainbanner" && $.isEmptyObject(leftBannerData))
            leftBannerData = element;
          return false;
        });

        this.props.dispatch({type : stateHandlerClass.UPDATE_BANNER, leftBanner :leftBannerData, bannerList : rightBannerDataS});

        util.debug("App loadNewsLists rightBannerDataS");
        util.log(rightBannerDataS);

      }

      // Recruit List
      if (aRtn.page !== undefined) {

        let recruitNewTemp = [];
        let recruitCareerTemp = [];
        let recruitListTemp = aRtn.page.filter(function(element)
        {
          if ( "recruitfyj-cap-new" === element.meta )
            recruitNewTemp.push(element);
          else if ( "recruitfyj-cap-career" === element.meta )
            recruitCareerTemp.push(element);

          // TODO HCY 2019OCT23 in the future may add more checking
          //if (element.cate_name === "sidebanner")
          return element;
          //  return false;
        });

        this.props.dispatch({
            type : stateHandlerClass.UPDATE_RECRUIT
          , recruitList : recruitListTemp
          , recruitNew : recruitNewTemp
          , recruitCareer : recruitCareerTemp
        });

        util.debug("App loadNewsLists recruitListTemp");
        util.log(recruitListTemp);

        util.debug("App loadNewsLists recruitNewTemp");
        util.log(recruitNewTemp);

        util.debug("App loadNewsLists recruitCareerTemp");
        util.log(recruitCareerTemp);

      }

      if( "" !== url2 )
      {
        svr.setBackupAPI(false);
      }
      else
      {
        svr.setBackupAPI(true);
      }

    })
    .catch(error => {
      util.error("App loadNewsLists");
      util.logErr(error);

      if( "" !== url2 )
      {
        this.loadNews(url2);
      }

    });

  }

  ////////////////////////////////////////

  render() {
    return <Router>
      <div className="App">
        <div className='root  root_w' >
          <div className='bg  bg_w' >

            <div className="middle2">
              <HeaderFYJ />
            </div>

            <HeaderBar />

            <BodyFYJ />
            {/*<div className="middle2">
              <BodyFYJ />
            </div>*/}

            <FooterFYJ />

          </div>
        </div>
      </div>
    </Router>;

  };

    /*

    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Foo Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <NewscapTop />
      </header>
    </div>
    */

}


const appStateToProps = state =>(
  {
    //moviveSetting : state.moviveSetting,
    newsContentSetting : state.newsContentSetting,
    //pageOptionS : state.pageOptionS,
    //pageMode : state.pageMode
  });

//export default App;
export default connect(appStateToProps)(App)
