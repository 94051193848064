import React from 'react';

import './ServiceDev.css';

import ServiceInfo from './ServiceInfo.js';

import ServiceInfoDev from './ServiceInfoDev.js';

import * as svc from '../../module/ServiceFYJ.js';

class ServiceDev extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="ServiceDev  " >

        {
            svc.serviceDev().map((el, index)=>
            {
                return (
                    <ServiceInfo 
                        key={index}
                        data={el}
                        content={ServiceInfoDev}
                    />
                )

            })
        }

      </div>
        
    }

}

export default ServiceDev;