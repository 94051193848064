import React from 'react';

import { HashRouter as Router ,Route ,Link ,NavLink } from "react-router-dom";

import './FooterFYJ.css';

function FooterFYJ() {
  return (
    <div className="FooterFYJ  debugFYJ">

        <div className="middle2">

            {/* Privacy */}
            <Link 
            to="/privacy"
            className="debugFYJ  Footer_btn  Footer_btn_privacy"
            ><span>プライバシーポリシー</span></Link>

            <Link 
            to="/contract"
            className="debugFYJ  Footer_btn  Footer_btn_contract"
            ><span>利用規約</span></Link>

            <Link 
            to="/law_shop"
            className="debugFYJ  Footer_btn  Footer_btn_law_shop"
            ><span>特許商取引</span></Link>

            <Link 
            to="/contact"
            className="debugFYJ3  Footer_btn  Footer_btn_contact"
            ><span>お問い合わせ</span></Link>
            
        </div>
<span className='FYJ_copyright'>© FUNYOURS JAPAN CO., LTD. All Rights Reserved.</span>
    </div>
  );
}

export default FooterFYJ;
