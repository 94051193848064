import React from 'react';

import './ServiceDesign.css';

import * as svc from '../../module/ServiceFYJ.js';

class ServiceDesign extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="ServiceDesign  " >

        <div className = "SD-cap  debugFYJ2"  />

        {
            svc.serviceDesign().map((el, index)=>
            {
                let urlPic = process.env.PUBLIC_URL + el.pic;
                if( el.pic.startsWith('http') )
                    urlPic = el.pic;

                return (
                    <div className = "SD-Row  debugFYJ2" key={index} >
                        <img
                            className = "SD-img  debugFYJ3"
                            src={urlPic}
                            alt=""
                        />
                        <br />
                    </div>
                )

            })
        }

      </div>
        
    }

}

export default ServiceDesign;