import React from 'react';

import { connect } from 'react-redux'

import './RecruitFYJ.css';

import RecruitJob from './RecruitJob';

class RecruitFYJ extends React.PureComponent{

    constructor(props) {
        super(props);
        this.foo = {};
    }

    componentDidMount() {
      window.gtagPageview(this.props.location.pathname);
    }

    componentWillUnmount() {

    }

    render() {

      return  <div className="RecruitFYJ  debugFYJ">

        <div className="RecruitFYJ-Cap"></div>

        <div className="RecruitFYJ-Cap-List  debugFYJ2">
        {
            this.props.recruitList.map((op, index)=>
            {

                return <RecruitJob
                    key={index}
                    recruitData={op}
                />

            })
        }
        </div>


        {/*

        <br></br>

        <div className="RecruitFYJ-Resume  debugFYJ2">
          <div className="RecruitFYJ-Cap-Resume"></div>
          <br />
          <span className="RecruitFYJ-Txt">TODO .. will replace as Table 。</span>
        </div>


        <br></br>
        <br></br>

        
        <div className="RecruitFYJ-Cap-New"></div>

        <br></br>
        <br></br>
        <span className="RecruitFYJ-Txt">現在、募集は行っておりません。</span>

        <br></br>
        <br></br>
        
        <div className="RecruitFYJ-Cap-Career"></div>
        <br></br>
        <br></br>
        <span className="RecruitFYJ-Txt">現在、募集は行っておりません。</span>
        */}

      </div>

    }

}

const recruitStateToProps = state=>({
    recruitList : state.recruitList,
    recruitNew : state.recruitNew,
    recruitCareer : state.recruitCareer
});
export default connect(recruitStateToProps)(RecruitFYJ);

