/* eslint-disable prettier/prettier */
import axios from 'axios';

import * as util from "./Util.js";

//import ServerList from "@/json/svr_list.json";
//const ServerList = require("../assets/content.json"); // HCY 2019APR24 try dynamically load it.

var svrID = 0;

var ServerList = {};

//var urlNewsAxios = {};

var mIsBackupAPI = false;

function serverInfo() {

  //util.debug('serverInfo svrID='+svrID);

  if ("undefined" === typeof ServerList[svrID])
    return null;
  else
    return ServerList[svrID];
}

function init( sid ,svrList ) {
    svrID = sid;
    ServerList = svrList;
    util.debug('init ID='+sid);
    util.log(svrList);

    // TODO: undef
    /*
    urlNews = axios.create({
        baseURL: svrList.url_news
    });
    //*/

}

// HCY 2019OCT02 Obsolete, less flexible
function urlNews() {

  let si = serverInfo();
  util.debug("urlNews()  SvrInfo.url_news=" + si.url_news);

  return axios.create({
      baseURL: si.url_news
  });

}

function urlContact() {

  let si = serverInfo();
  util.debug("urlContact()  SvrInfo.url_contact=" + si.url_contact);

  return axios.create({
      baseURL: si.url_contact
  });

}

// HCY 2019OCT02 Now assume if NewsList API changed to backup,
// Then all related API shift to backup.
function url_news_detail() {

  let si = serverInfo();
  if( mIsBackupAPI )
  {
    return si.backup_url_news_detail;
  }
  else
  {
    return si.url_news_detail;
  }

}
function url_banner() {

  let si = serverInfo();
  if( mIsBackupAPI )
  {
    return si.backup_url_banner;
  }
  else
  {
    return si.url_banner;
  }

}

function isBackupAPI()
{
  return mIsBackupAPI;
}
function setBackupAPI( isBackupAPI )
{
  util.debug("setBackupAPI() " + isBackupAPI);
  mIsBackupAPI = isBackupAPI;
}

function isGlobalBackupURL()
{
  let si = serverInfo();

  if( typeof si.global_backup_url )
  {
    return (true === si.global_backup_url);
  }

  return false;
}

export {serverInfo ,init ,urlNews ,urlContact ,url_news_detail ,url_banner ,isBackupAPI ,setBackupAPI ,isGlobalBackupURL };
