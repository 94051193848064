import React from 'react';

import './NewsdetailBody.css';

class NewsdetailBody extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="NewsdetailBody  debugFYJ3" >

          <div className='NewsdetailBody-Content  debugFYJ' dangerouslySetInnerHTML={{__html : this.props.contentData.content}}></div>

          {/*}
          TODO NewsdetailBody..
          Prev:{this.props.contentData.prevIndex}
          Next:{this.props.contentData.nextIndex}
          */}


      </div>
        
    }

}

export default NewsdetailBody;