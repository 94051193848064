import React from 'react';

import './NewsPageBtn.css';

import * as util from "../../module/Util.js";

class NewsPageBtn extends React.Component{

    constructor(props) {
        super(props);

        this.foo="";
        /*
        this.props.idx = props.idx;
        this.props.clickBtnDo = props.clickBtnDo;
        this.props.isSelected = props.isSelected;
        */
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //////////////////////////
    cssStr()  {
        let cssStr = "npb_btn ";

        if( 0 === this.props.idx )
          cssStr += "npb_icon3dot";
        else if( -1 === this.props.idx )
          cssStr += "npb_iconL";
        else if( 9999 === this.props.idx )
          cssStr += "npb_iconR";
        else if( this.isNumSelected() )
          cssStr += "npb_icon_selected";
        else
          cssStr += "npb_icon";

        return cssStr
    }

    label() {
        if( 0 < this.props.idx && this.props.idx < 9999 )
            return this.props.idx;
  
        return "";
    }

    isNumSelected () {
        if( null != this.props.isSelected && 'undefined' != typeof this.props.isSelected )
        {
            util.debug("NewsPageBtn isNumSelected this.props.idx=" + this.props.idx);

            return this.props.isSelected( this.props.idx );
        }


  
        return false;
    }

    clickBtn () {
        if( null != this.props.clickBtnDo && 'undefined' != typeof this.props.clickBtnDo )
          this.props.clickBtnDo( this.props.idx );
    }

    //////////////////////////

    render() {

      return <div className={this.cssStr()}  onClick={() => this.clickBtn()}  >
          {this.label()}
      </div>
        
    }

}

export default NewsPageBtn;