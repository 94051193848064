import React from 'react';

import Moment from 'moment';

import axios from 'axios';

import { connect } from 'react-redux';

import * as svr from '../../module/SvrInfo.js';

import * as util from "../../module/Util.js";

import './Newsdetail.css';

import NewsdetailHeader from './NewsdetailHeader.js';
import NewsdetailBody from './NewsdetailBody.js';
import NewsdetailFooter from './NewsdetailFooter.js';

function defaultContentData()
{
    return {
        title: 'データが存在しません',
        startMoment: Moment(new Date()), //Moment(new Date()).format('YYYY.MM.DD'),
        prevIndex: -1,
        nextIndex: -1,
        content: ""
    }
}

// HCY 2019OCT03 for duel loading if using backup URL
function loadingContentData()
{
    return {
        title: '読み込み中...',
        startMoment: Moment(new Date()), //Moment(new Date()).format('YYYY.MM.DD'),
        prevIndex: -1,
        nextIndex: -1,
        content: ""
    }
}

/*
var defContent = { // #TEST ONLY
    title: 'データが存在しません #TEST',
    startMoment: Moment(new Date()),
    prevIndex: -1,
    nextIndex: -1,
    content: ""
}
*/


class Newsdetail extends React.Component{

    constructor(props) {

        util.debug("Newsdetail  Cons props.match.params.newsId=" + props.match.params.newsId);

        super(props);
        this.state = {
            newsId : this.props.match.params.newsId,
            //contentData : defaultContentData()
            contentData : loadingContentData()
        };

        this.loadContentData(this.state.newsId);
    }

    componentDidMount() {
        window.gtagPageview(this.props.location.pathname);
    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps)
    {
        util.debug("Newsdetail  componentDidUpdate  this.state.newsId=" + this.state.newsId);
        util.debug("Newsdetail  componentDidUpdate  prevProps.match.params.newsId=" + prevProps.match.params.newsId);
        util.debug("Newsdetail  componentDidUpdate  this.props.match.params.newsId=" + this.props.match.params.newsId);

        util.debug("Newsdetail  componentDidUpdate  prevProps.newsList.len=" + prevProps.newsList.length);
        util.debug("Newsdetail  componentDidUpdate  this.props.newsList.len=" + this.props.newsList.length);

        let lastId = this.props.match.params.newsId;

        if (lastId !== this.state.newsId)
        {
            this.setState(
                {newsId : lastId},
                ()=>{
                    this.loadContentData( this.state.newsId );
                }
            );
        }
        else if (prevProps.newsList !== this.props.newsList)
        {
            this.loadContentData( this.state.newsId );
        }

    }

    /////////////////////////////////

    loadContentData(newsid ,url_news_detail="") {

        util.debug("loadContentData ??????? START newsid=" + newsid);
        util.log(this.props.newsList);

        var tmpIndex = this.props.newsList.findIndex(function (el) {
          return el.link_url === newsid
        });
  
        if( -1 === tmpIndex ) {
          util.debug("loadContentData findIndex get -1");
          //return; // HCY 2019OCT02 Allow news detail not in news list.
        }
  
        // == == ==
        let url_req = '/' + newsid + '.html?t=' + new Date().getTime();
        let url1 = url_news_detail + url_req;
        if( "" === url_news_detail )
        {

            if( svr.isGlobalBackupURL() )
            {
                url1 = svr.url_news_detail() + url_req;
            }
            else
            {
                let svrInfo = svr.serverInfo();
                url1 = svrInfo.url_news_detail + url_req;
            }

        }
        util.debug("loadContentData try get from "+url1);

        //var tmpContent = defaultContentData();
        var tmpContent =  loadingContentData();
        //var tmpContent = defContent; // #TEST
  
        axios
          .get(url1)
          .then(response => {

            util.debug("loadContentData ??????? OK");
            //util.log(response.data);
  
            var aRtn = response.data;

            let newsList_ = this.props.newsList;

            if( -1 !== tmpIndex )
                tmpContent = newsList_[tmpIndex];

            tmpContent.prevIndex = tmpIndex === 0 || tmpIndex === -1 ? -1 : newsList_[tmpIndex - 1].link_url
            tmpContent.nextIndex = tmpIndex === newsList_.length - 1 || tmpIndex === -1 ? -1 : newsList_[tmpIndex + 1].link_url
            tmpContent.content = aRtn

            util.log(tmpContent);

            //this.contentData = tmpContent
            //this.updateContent++;
            this.setState({contentData :tmpContent});

          })
          .catch(error => {
            util.error("Newsdetail loadContentData");
            util.logErr(error);

            util.log(tmpContent);

            if( "" === url_news_detail )
            {
                let svrInfo = svr.serverInfo();
                this.loadContentData(newsid ,svrInfo.backup_url_news_detail);
            }
            else
            {
                this.setState({
                    contentData :defaultContentData()
                });
            }


          });
  
        // == == ==
    }

    /////////////////////////////////

    render() {

      return <div className="Newsdetail  debugFYJ" >

          <NewsdetailHeader contentData={this.state.contentData} />
          <NewsdetailBody contentData={this.state.contentData} />
          <hr className="Newsdetail-Bar-Footer" />
          <NewsdetailFooter contentData={this.state.contentData} />

          {/*<h3>{this.props.match.params.newsId}</h3>*/}

      </div>
        
    }

}


const newsdetailStateToProps = state=>(
    {
        newsList : state.newsList,
    });
export default connect(newsdetailStateToProps)(Newsdetail);