import React from 'react';

import './LawShop.css';

class LawShop extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.gtagPageview(this.props.location.pathname);

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="LawShop" >

        <div className="LawShop-Cap" />

        <table className="LawShop-table  debugFYJ2" >
            <tbody>
                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    事業者名称
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    株式会社FUNYOURS JAPAN
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    代表者名
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    洪　而立
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    お問い合わせ先
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                        お問い合わせ先：<a href="mailto:info@funyours.co.jp" className="Privacy-Txt-Mail">info@funyours.co.jp</a>
                        <br />
                        ※連絡先電話番号についても、上記お問い合わせ用メールアドレスまで
                        <br />
                        　ご請求いただければ、遅滞なく開示いたします。
                        <br />
                        ※電話でのお問い合わせについては、お問い合わせ内容の確認、および
                        <br />
                        　サービス向上のため、お電話の内容を録音させていただいております。
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    ホームページ
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    <a href="https://www.funyours.co.jp/" className="Contract-Txt-Mail"  target="_blank"  rel="noopener noreferrer"  >https://www.funyours.co.jp/</a>
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    販売価格
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    購入画面に記載しております。
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    お支払い方法
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    クレジットカード決済、電子マネー決済、携帯電話会社による回収代行
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    代金のお支払い時期
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    商品提供の前
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    引渡し時期
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    ご購入手続き完了後、即座にお引渡し。
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                     商品代金以外の必要料金
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    ・消費税は内税表示としております。
                    <br />
                    ・インターネット接続料金や通信料金等につきましてはお客様のご負担となります。
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    返品期限・返品送料
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    商品等の性質上、ご購入手続き後の返品や交換はお受けできませんので、
                    <br />
                    あらかじめご了承ください。
                    </td>
                </tr>

                <tr>
                    <td className="LawShop_td_ttl" align="left" valign="top">
                    不良品
                    </td>
                    <td className="LawShop_td_ctt" align="left" valign="top">
                    ポイント購入処理中の回線切断など、特殊な状況が発生し処理が中断された等、商品等の利用に支障をきたす瑕疵が発生した場合、当該瑕疵の修補または代替品提供など、迅速に対応いたします。決済が正常に処理されなかった場合には、「問い合わせ窓口」までご連絡をお願いいたします。
                    </td>
                </tr>

            </tbody>
        </table>



      </div>
        
    }

}

export default LawShop;