import React from 'react';

import './ServiceInfoGame.css';

class ServiceInfoGame extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    // <div className="ServiceInfoGame  debugFYJ3" >
    // </div>

    render() {

      return <span className="ServiceInfoGame  debugFYJ3" >

          {/*<div className="SIG-Context  debugFYJ2">{this.props.data.context}</div>*/}
          <div className="SIG-Context  debugFYJ2"  dangerouslySetInnerHTML={{__html : this.props.data.context}}></div>

          <div className="SIG-Btns  debugFYJ3" >

            <a  className="SIG-Btn-Website"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.data.url_website}
            ><span></span></a>

            <a  className="SIG-Btn-Twitter"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.data.url_twitter}
            ><span></span></a>

          </div>


      </span>

    }

}

export default ServiceInfoGame;