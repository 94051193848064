import React from 'react';

import * as svr from '../../module/SvrInfo.js';

import './ElderarkContract.css';


class ElderarkContract extends React.PureComponent{

  componentDidMount() {
    window.gtagPageview(this.props.location.pathname);

}

componentWillUnmount() {

}
  render() {

    return <div className="Contract  debugFYJ" >

      <div className="Contract-Txt-Abs  debugFYJ2">『エルダーアーク』　サービス利用規約</div>

      <hr />
      <br />

      <div  className="Contract-Body  debugFYJ3" > {/* TEXT BODY START */}

<span className="Contract-Txt-Cap  debugFYJ2">
第1章 目的
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	本規約は株式会社FUNYOURS JAPAN（以下「当社」といいます）が提供、運営する『エルダーアーク』（以下「本サービス」といいます）の利用に関し、本サービスの利用者と当社の間に適用されるものとします。
</div>
<div className="Contract-Txt  debugFYJ3">
2.	本サービスの利用者は、本サービスの利用に際し、本規約に同意する必要があります。
当社が指定する方法で利用申請を完了する事によって、利用者は本規約の全ての条件に同意するものとします。
</div>



<div className="Contract-Txt  debugFYJ3">
3.	本サービスの利用には、本規約が適用されるものとします。</div>
<div className="Contract-Txt  debugFYJ3">
4.	本サービスの利用者が未成年者（20歳未満）等の制限行為能力者である場合、本サービスの利用に関し親権者等、法定代理人の同意が必要となります。法定代理人の同意がない場合、本サービスの利用はできないものとします。<br />
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第2章 用語の定義
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	「利用者」とは、本規約に同意した者をいいます。</div>
<div className="Contract-Txt  debugFYJ3">
2.	「利用者情報」とは、利用者になるために必要な情報をいいます。</div>
<div className="Contract-Txt  debugFYJ3">
3.	「利用者アカウント」とは、利用者が本サービスにログインするために必要な利用者を識別する情報をいいます。
</div>

<div className="Contract-Txt  debugFYJ3">
4.	「プレイヤーデータ」とは、本サービスに関して、利用者アカウントが保有する、本規約第8条で定める翼晶石等のデータをいいます。
</div><div className="Contract-Txt  debugFYJ3">
  5.	「翼晶石」とは、有料サービスを利用する際に必要な、本サービス内のみで利用できる仮想通貨をいいます。
</div><div className="Contract-Txt  debugFYJ3">
6.	「翼晶石」は、本サービスより利用者に無償で提供される「無償翼晶石」と、利用者が購入する「有償翼晶石」の2種類があります。
</div><div className="Contract-Txt  debugFYJ3">
7.	「本サイト」とは当社が運営する本サービスにおける公式ホームページ（<a href="https://elderark.funyours.co.jp/" target="_blank">https://elderark.funyours.co.jp/</a> ）をいいます。
</div>


<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第3章 本サービスの利用
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	本サービスは、「利用規約」の定め、および所定の申請手続きに従い、利用者となった方が利用することができます。
</div><div className="Contract-Txt  debugFYJ3">
2.	本サービスの利用にあたり必要な端末、ソフトウェア、ハードウェア、回線等の設備や電気代、通信代、インターネット接続にかかる費用等については、全て利用者の責任、負担とします。
</div><div className="Contract-Txt  debugFYJ3">
3.	当社は、本規約に違反した場合や、利用者登録にあたり虚偽の申告、本サービスの利用に関し第三者になりすます等、不正利用が行われたと当社が判断した利用者の本サービスの利用停止、アカウントの削除、その他当社が必要と認める本サービスの利用を制限できるものとします。また、当該不正利用が行われたと当社が判断した利用者アカウントを保有する利用者が、当該アカウント以外にも利用者アカウントを保有している場合には、それらの利用者アカウントについても同様の処理を行えるものとします。
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第4章 利用者の義務
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	利用者は、利用者アカウントの使用、及び管理について一切の責任を負うものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	本サービスを利用する利用者の権利は、利用者本人のみに帰属します。利用者は、利用者アカウントを第三者に利用させたり、貸与、譲渡、名義変更、売買、質入等をしたりしてはならないものとします。
</div><div className="Contract-Txt  debugFYJ3">
3.	利用者アカウントの管理不十分、使用上の過誤、第三者の利用等による損害の責任は、利用者が負うものとし、当社は一切責任を負いません。当社は、利用者の利用者アカウントで生じた全ての行為を、当該アカウントを保有する利用者自身の行為とみなします。
</div><div className="Contract-Txt  debugFYJ3">
4.	利用者は、利用者アカウントが盗用されたり、盗用された恐れがある場合、又は第三者に利用されている可能性がある場合には、直ちに当社へ届け出るとともに、当社からの指示がある場合には、その指示に従うものとします。
</div><div className="Contract-Txt  debugFYJ3">
5.	利用者が、本サービスの利用に起因、又は関連して、第三者、又は当社に対して損害を与えた場合、自己の責任と費用において損害を賠償するものとします。
</div><div className="Contract-Txt  debugFYJ3">
6.	利用者が、本条で定める利用者の義務を怠った事により、いかなる不利益を被ったとしても、当社は一切の責任を負わないものとします。

</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第5章 個人情報の利用目的と第三者への開示
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社は、予め本規約に同意している利用者に対し、当社の提供するサービスの広告を目的とした通知を送信する場合があるものとします。また、その通知は、利用者が当社の定める所定の手続きを行う事により停止する事ができます。
</div><div className="Contract-Txt  debugFYJ3">
2.	当社は、下記の各項目に該当する場合を除き、利用者情報のうち、個人情報を第三者に開示しないものとします。
<br></br><div className="Contract-Txt-Hisi">①	当社が、本サービスの利用動向を把握する目的で収集した情報を、統計データとして
パートナーとなりうる企業・広告主等の第三者に、個人を特定できない範囲で開示
する場合。
<br></br></div><div className="Contract-Txt-Hisi">②	利用者が、個人情報の開示について別途明示的に同意している場合。
<br></br></div><div className="Contract-Txt-Hisi">③	利用者情報の管理のために、個人情報の取扱いの全部、又は一部を委託する場合。
<br></br></div><div className="Contract-Txt-Hisi">④	裁判所や警察等の公的機関から、法令に基づく正式な照会を受けた場合。
<br></br></div><div className="Contract-Txt-Hisi">⑤	人の生命、身体、及び財産等に対する差し迫った危険があり、緊急の必要性がある場合。
<br></br></div><div className="Contract-Txt-Hisi">⑥	利用者の規約違反により、当社の権利・財産を守るために当該利用者の個人情報の開示が必要となる場合。
</div></div><div className="Contract-Txt  debugFYJ3">
3.	本条項に定める他、当社は、当社が別途定める「プライバシーポリシー」に基づき、利用者が本サービスを利用する過程において当社が知り得た個人情報を適切に取扱うものとします。

</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第6章 利用者資格の喪失
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
</div><div className="Contract-Txt  debugFYJ3">1.	当社は、虚偽の登録等、利用者の行為が本規約に違反すると判断した場合、事前通知、又は当該利用者の承諾を要する事なく、当該利用者の利用者資格の一部、又は全部を抹消できるものとします。
</div><div className="Contract-Txt  debugFYJ3">2.	その他、当社が必要と判断した場合には、同様の処理ができるものとします。
</div><div className="Contract-Txt  debugFYJ3">3.	当社は、停止理由の如何を問わず、利用者が本サービスの利用を停止した後も、利用者がサービスを利用する過程において収集された個人を特定できない範囲の情報を、当社のサービス向上等、当社が適当と判断する目的のために利用できるものとします。
</div><div className="Contract-Txt  debugFYJ3">4.	本規約において同意した事項について、当該事情が存続しているものについては、停止理由の如何を問わず、利用者が本サービスの利用を停止した後も効力を有するものとします。

</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第7章 プレイヤーデータ
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社は、次のいずれかの場合、利用者に事前に通知する事なく、プレイヤーデータを任意に削除、変更、又は移動する事ができるものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	本規約第6条で定める事由により利用者が利用者資格を喪失した場合。
</div><div className="Contract-Txt  debugFYJ3">
3.	当社が本サービスの運営、及び保守管理上必要であると判断した場合。
</div><div className="Contract-Txt  debugFYJ3">
4.	当社が本サービスの提供の妨げになると任意に判断した場合。
</div><div className="Contract-Txt  debugFYJ3">
5.	当社が不適当なデータ通信であると任意に判断した場合。
</div><div className="Contract-Txt  debugFYJ3">
6.	その他、当社が必要であると判断した場合。
</div><div className="Contract-Txt  debugFYJ3">
7.	当社は、理由の如何を問わず、プレイヤーデータの全て、又は一部について、他のプレイヤーデータへの移し変えに応じないものとします。
</div><div className="Contract-Txt  debugFYJ3">
8.	利用者は、プレイヤーデータの一部、又は全てを第三者に譲渡、貸与、売買等一切してはならないものとします。

</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第8章 翼晶石
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	利用者は、当社が定める決済手段・方法により「有償翼晶石」の購入手続きを行うことができるものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	「有償翼晶石」の購入は、「有償翼晶石」を購入する際の最終購入確認画面で利用者が購入ボタンをタップした時点で完了します。
</div><div className="Contract-Txt  debugFYJ3">
3.	当社は、本規約に定めがある場合を除き、購入完了後の「有償翼晶石」の購入代金の返金等には応じません。
</div><div className="Contract-Txt  debugFYJ3">
4.	当社は、「有償翼晶石」の購入完了を確認後、速やかに「有償翼晶石」を発行しますが、システム上、インターネットおよびサーバーの特性上、及びその他の理由で「有償翼晶石」購入手続き完了から「有償翼晶石」提供完了までに、時間差が生じる可能性があることをあらかじめ了承するものとします。
</div><div className="Contract-Txt  debugFYJ3">
5.	当社は、「有償翼晶石」の購入等に関わる領収書、又は通知書等を発行しないものとします。
</div><div className="Contract-Txt  debugFYJ3">
6.	システム障害、システム保守の点検、その他安全管理のため、当社が必要と判断した場合には、「有償翼晶石」の購入が停止される場合があります。
</div><div className="Contract-Txt  debugFYJ3">
7.	翼晶石の取得、又は利用時において、価格、説明、注文確認、注文処理、発行、消費処理、その他に関してエラーが生じた場合、当社は、これらのエラーを修正し、必要な場合はそれに応じて翼晶石の数値等を修正することができるものとします。
</div><div className="Contract-Txt  debugFYJ3">
8.	翼晶石に関連する説明で、「取得」、「購入」、「買取り」、「販売」、「注文」等の用語が使用されていても、利用者は「有償翼晶石」、及び有料サービスについて使用許諾のライセンスを取得するにすぎず、所有権その他の権利を取得しません。
</div><div className="Contract-Txt  debugFYJ3">
9.	翼晶石は、利用者が「無償翼晶石」を所有していた場合、「無償翼晶石」から消費されます。また、獲得日時の古い翼晶石から先に使用されます。
</div><div className="Contract-Txt  debugFYJ3">
10.	利用者が保有する翼晶石は、次の各号に該当する場合に失効する事とします。
</div><div className="Contract-Txt  debugFYJ3">
11.	本規約第11条で定める事由により本サービス全体が終了する場合。
</div><div className="Contract-Txt  debugFYJ3">
12.	本規約第6条で定める事由により利用者資格を喪失し、プレイヤーデータが削除された場合。
</div><div className="Contract-Txt  debugFYJ3">
13.	取得した翼晶石を他の利用者アカウントに移転、譲渡、貸与する事、並びに現金その他の金券、金品に交換する事はできないものとします。
</div><div className="Contract-Txt  debugFYJ3">
14.	利用者は、翼晶石を取得する際に使用した利用者アカウント以外のアカウントで翼晶石を利用する事はできないものとします。
</div><div className="Contract-Txt  debugFYJ3">
15.	当社は、当社が特に認める場合を除き、一度購入された「有償翼晶石」の払い戻し、及び第三者の発行する電子通貨等への変換は行いません。
</div><div className="Contract-Txt  debugFYJ3">
16.	利用者の翼晶石の利用は、営業目的ではない、私的な利用に限られます。
</div><div className="Contract-Txt  debugFYJ3">
17.	当社は、当社の責に帰するべき事由による場合を除き、翼晶石の取得、利用、又は利用不能に起因、又は関連して生じた損害について責任を負担いたしません。また、当社が負う責任は、当社の故意又は重過失による場合を除き、間接的、又は派生的な損害その他の損害（逸失利益の損害、及びデータの損失を含みますがこれらに限られません）には及ばず、また、利用者が「有償翼晶石」の対価として当社に支払った金額を超えないものとします。
</div><div className="Contract-Txt  debugFYJ3">
18.	未成年者が翼晶石の購入手続きを行う際は、親権者等、法定代理人が同意した上で手続きを行う必要があります。未成年者が翼晶石を購入した場合、法定代理人の合意を得たものとみなします。利用制限があるキャリア決済をご利用の場合には、当該キャリアとの契約の利用制限に従うものとします。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第9章 サポート
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社による本サービスに関するサポートを利用しようとする方は、本規約第3条で定める利用者の要件を満たすものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	当社は、本サービスに関するサポートを、日本語に限り提供するものとします。
</div><div className="Contract-Txt  debugFYJ3">
3.	利用者からの意見・要望・不具合報告を含む問い合わせ全般に関しては、当社所定の方法にて受け付けるものとします。回答の必要性の有無については当社にて判断を行い、回答の必要の無いものと判断された内容に対して当社は回答を行わないものとします。なお、不具合か否かの判断は、当社にて行うものとします。
</div><div className="Contract-Txt  debugFYJ3">
4.	当社は、プレイヤーデータの調査を目的として、利用者への通知、又は承諾を要する事なく、利用者アカウントの本サービスの利用を一時的に制限できるものとします。
</div><div className="Contract-Txt  debugFYJ3">
5.	当社は、本サービスが別途動作を保証する日本語のオペレーティングシステム以外でのサポートは一切行わないものとします。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第10章  禁止事項
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	利用者は、本サービスの利用にあたり、次の各号に記載の行為、及び個別規約で禁止している行為の一切を行ってはならないものとします。
<br></br>  <div className="Contract-Txt-Hisi">① 公序良俗に反する行為。
<br></br>  </div>
<div className="Contract-Txt-Hisi">②	侮蔑的、迫害的な表現など他人を不快にさせる行為。
<br></br></div><div className="Contract-Txt-Hisi">③	有害なプログラム・スクリプト等をアップロードする行為。
<br></br></div><div className="Contract-Txt-Hisi">④	第三者の知的財産権(特許権、実用新案権、意匠権、商標権、著作権、名誉権、肖像権、プライバシー権、パブリシティ権、その他法令上又は契約上の権利)を侵害する行為。
<br></br>  </div>
<div className="Contract-Txt-Hisi">⑤	法令、裁判所の判決、決定、もしくは命令、又は法令上拘束力のある行政措置に違反する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑥	当社、又は第三者になりすます行為、又は意図的に虚偽の情報を流布させる行為。
<br></br></div><div className="Contract-Txt-Hisi">⑦	当社の許諾を得ない営業、宣伝、広告、勧誘、その他営利を目的とする行為。
<br></br></div><div className="Contract-Txt-Hisi">⑧	反社会的勢力に対する利益供与、その他の協力行為。
<br></br></div><div className="Contract-Txt-Hisi">⑨	宗教活動、又は宗教団体への勧誘行為。
<br></br></div><div className="Contract-Txt-Hisi">⑩	他人の個人情報、登録情報などを、不正に収集、開示、又は提供する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑪	自殺、集団自殺、自傷、違法薬物使用、脱法薬物使用等を勧誘・誘発・助長するような行為。
<br></br></div><div className="Contract-Txt-Hisi">⑫	性交、及びわいせつな行為を目的とした出会い等を誘導する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑬	当社の許諾を得ない売買行為、オークション行為、金銭支払や、その他の類似行為。
<br></br></div><div className="Contract-Txt-Hisi">⑭	他人の名義、その他会社等の組織名を名乗ること等による、なりすまし行為。
<br></br></div><div className="Contract-Txt-Hisi">⑮	ストーカー行為、又はストーカー行為に類似する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑯	チェーンメール、スパムメール等を送信する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑰	ゲームサイトが提供するソフトウェア（以下「本ソフトウェア」といいます）の逆コンパイル、逆アセンブル、リバースエンジニアリング等、本ソフトウェアのソースコードを解析する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑱	本サービスに関わる各情報の改竄、消去等、不正なアクセス行為、及び、行おうと試みる行為。
<br></br></div><div className="Contract-Txt-Hisi">⑲	本サービス、もしくは本ソフトウェアの不具合、障害、又は脆弱性を利用する行為。
<br></br></div><div className="Contract-Txt-Hisi">⑳	本サービスの不具合や障害を不正な目的で利用すること、それを他者へ伝達する行為。
<br></br></div><div className="Contract-Txt-Hisi">㉑  マクロツールなどゲームに影響を及ぼすことができる、当社に認められていないユ
ーティリティ（外部プログラム・ツール）の使用、作成、及び提供、及びそれらの
送信、支援、宣伝する行為。サーバエミュレーターの利用やユーティリティーの開
発行為、又はその利用行為。
<br></br></div><div className="Contract-Txt-Hisi">㉒  本サイト、又は本サービス内での掲示、告知等に違反する行為。
<br></br></div><div className="Contract-Txt-Hisi">㉓  本サービス運営からの確認に応答し、協力要請に従って頂かない行為。又は警告を
受けたにも関わらず従わない行為。
<br></br></div><div className="Contract-Txt-Hisi">㉔ 本規約第4条で定める利用者の義務を怠る行為。
<br></br></div><div className="Contract-Txt-Hisi">㉕ 当社が本サイト等で禁止を告知した行為。
<br></br></div><div className="Contract-Txt-Hisi">㉖ その他、当社の管理運営を妨げる等、当社が不適切と判断する行為2. 前項各号のい
ずれかに該当する行為によって当社、及び第三者に損害が生じた場合、本サービスの利用者資格の喪失如何に関わらず、利用者は全ての法的責任を負うものとします。
</div>
<br></br></div>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第11章 サービスの変更、制限、一時中止、終了
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社は、本サービスの運営・提供に関し、次のいずれかに該当すると判断した場合、利用者への事前通知、又は承諾を要する事なく本サービス全体、又は一部を変更、制限、一時中止、もしくは終了する事ができるものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	本サービスの運営・提供に必要な定期メンテナンスを行う場合。
</div><div className="Contract-Txt  debugFYJ3">
3.	本サービスの運営・提供に必要な設備の故障等の事由により緊急に保守を行う場合。
</div><div className="Contract-Txt  debugFYJ3">
4.	戦争、暴動、騒乱、労働争議、地震、噴火、洪水、津波、火災、停電、その他の非常事態、又は不可抗力の事由により、本サービスの運営、又は提供が困難と当社が任意に判断した場合。
</div><div className="Contract-Txt  debugFYJ3">
5.	その他、運用上、又は技術上の理由でやむを得ない場合。
</div><div className="Contract-Txt  debugFYJ3">
6.	前項で定められた事由の他、当社は、当社の任意の判断において、利用者の承諾を要する事なく本サービス全体、又は一部を終了する事ができるものとします。
</div><div className="Contract-Txt  debugFYJ3">
7.	当社が本サービスを変更、制限、一時中止、又は終了した場合であっても、利用者に生じた損害について、当社は一切の責任を負わないものとします。


</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第12章 免責
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社は、本サービスを利用者に提供するにあたり、利用者が被った損害について、一切の責任を負わないものとします。ただし、当社の責めに帰すべき事由がある場合はこの限りではありません。
</div><div className="Contract-Txt  debugFYJ3">
2.	当社は、当社の責めに帰すべき事由により賠償責任を負う場合であっても、利用者に対する賠償責任の額は50,000円を上限とします。ただし、当社に故意又は重過失がある場合はこの限りではありません。
</div><div className="Contract-Txt  debugFYJ3">
3.	当社は、利用者同士、もしくは利用者と第三者において生じた紛争や損害につき、一切の責任を負わないものとします。
</div><div className="Contract-Txt  debugFYJ3">
4.	当社は、本規約第7条で定めるプレイヤーデータの削除、変更、及び移動につき何ら補償を行わないものとします。
</div><div className="Contract-Txt  debugFYJ3">
5.	当社の責に帰すべき事由によるか否かを問わず、プレイヤーデータが消失した場合について、当社は何人に対しても一切の責任を負わないものとします。利用者アカウントを第三者に利用された事によって損害が生じた場合でも、原則として当社は補償対応を行いません。

</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第13章 保証の否認
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	利用者は、自己の責任において本サービスを利用する事に明示的に合意するものとし、本サービスに起因して利用者が被った損害について全責任を負うものとし、当社は一切の責任を負わないものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	当社は、以下の内容について一切の保証を行うものではありません。
<br></br><div className="Contract-Txt-Hisi">
①	本サービスの内容が利用者の要求に合致する事。
<br></br></div><div className="Contract-Txt-Hisi">②	本サービスが中断されない事。
<br></br></div><div className="Contract-Txt-Hisi">③	本サービスにおいていかなる瑕疵もない事。
<br></br></div><div className="Contract-Txt-Hisi">④	利用者が本サービスを通じて取得する情報が正確であり、信頼できるものである事。
<br></br></div><div className="Contract-Txt-Hisi">⑤	本サービスにおいていかなる法的欠陥・瑕疵がない事。
<br></br></div><div className="Contract-Txt-Hisi">⑥	本サービスを通じて送受信した情報が所定の機器に保存される事、送信相手に受信される事、或いは画面上に表示される事。
</div></div><div className="Contract-Txt  debugFYJ3">
3.	当社は、利用者が意図する本サービスの利用目的への適合性、或いは有用性等に関し、いかなる保証もしません。
</div><div className="Contract-Txt  debugFYJ3">
4.	本サービスは現状有姿のまま提供され、本サービスの挙動が、当社指定のいずれかの方法で通知したサービスの説明等と異なっていた場合でも、本サービスの挙動を現況優先とし、本サービスに起因して利用者が被った損害について、当社は一切の責任を負わないものとします。

</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第14章 損害賠償
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	利用者の行為により当社が損害を被った場合、及び利用者の行為により他の利用者、又は第三者からクレームを受け当社が損害を被った場合、当社は当該利用者に対して損害賠償を請求できるものとし、当該利用者は直ちに請求金額を当社に支払うものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	利用者と他の利用者、又は第三者との間の紛争により当社が損害を被った場合、利用者は当社が被った損害を当社に賠償しなければならないものとします。
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第15章 権利の帰属
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	本サービス（本サービスを構成するデータ、プログラムを含む）に関する著作権、その他一切の権利は、当社、又は権利を有する第三者に帰属します。
</div><div className="Contract-Txt  debugFYJ3">
2.	本サービスにおいて利用者が公開した本サービスに関連するアイデア等を、当社は利用者の承諾を要する事なく、本サービスの改善の為に自由、かつ無償で利用できるものとします。
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第16章 規約の変更と追加
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社は、利用者の承諾を要する事なく、任意に本規約の全部、又は一部を変更、又は本規約に条項を追加する事ができるものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	本規約を変更、又は本規約に条項を追加する場合は、当社は本サイト等で利用者に対し、本規約の変更等を告知するものとします。なお、本規約の変更等は利用者が閲覧可能となった時点で効力が生じ、変更等後のすべての規約に同意したものとみなします。追加、又は変更内容を利用者が確認しない事により発生したいかなる損害について、当社は一切の責任を負わないものとします。
</div>
<br></br>
<br></br>

<span className="Contract-Txt-Cap  debugFYJ2">
第17章 管轄裁判所等
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	利用者と当社の間で紛争が生じた場合には、当該当事者がともに誠意をもって協議するものとします。
</div><div className="Contract-Txt  debugFYJ3">
2.	本規約、又は本サービスの利用に関し、訴訟の必要が生じた場合は、東京地方裁判所を第一審の専属的合意管轄とするものとします。
</div><div className="Contract-Txt  debugFYJ3">
3.	本契約の一部が、消費者契約法、その他の法律の強行規定部分により効力を有しないとされる場合でも、その他の部分はこれに反しない最大の範囲で効力を有するものとします。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Cap  debugFYJ2">
第18章 AppsFlyerに関する特約事項
</span>
<br></br>
<div className="Contract-Txt  debugFYJ3">
1.	当社は、本サービスの改良等を目的として本サービスの利用頻度等を測定するため、 本サービスにおいてAppsFlyer（以下「AppsFlyer」といいます）の提供する「AppsFlyer」を利用し、匿名のトラフィックデータを収集しています。AppsFlyerは、利用者の本サービス利用に基づく様々な情報を取得します。取得する情報およびその取り扱いについては、AppsFlyerのプライバシー・ポリシー（<a href="https://www.appsflyer.com/privacy-policy/" target="_blank" >https://www.appsflyer.com/privacy-policy/</a> ）をご確認ください。AppsFlyerが取得する情報が適用法規において個人情報に該当する場合、 ユーザーはAppsFlyerがそのプライバシー・ポリシーに基づき当該情報を収集、保管、共有、使用することに同意するものとします。 利用者は、AppsFlyerのプライバシー・ポリシーに定める手段により、「AppsFlyer」よりオプトアウトすることができます。
</div>
<br></br>
<br></br>


<span className="Contract-Txt-Date  debugFYJ3">
</span>

      </div>  {/* TEXT BODY END */}

    </div>
      
  }

}

export default ElderarkContract;