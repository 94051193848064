import React from 'react';

import './ServiceInfo.css';
import * as svc from "../../module/ServiceFYJ.js";

import picNull from '../../assets/img/empty.png';
import picAndr from './img/icon_android.png';
import picIOS  from './img/icon_ios.png';
import picPC   from './img/icon_pc.png';

class ServiceInfo extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      let picSty = {
          //backgroundImage:'url('+window.location.origin+'/public/img/'+this.props.data.pic+')' // xxx
          backgroundImage:'url('+process.env.PUBLIC_URL+''+this.props.data.pic+')' // O  this.props.data.pic  need set as "/aa/bb/cc"
          //backgroundImage:'url('+''+this.props.data.pic+')' // Also worked, but may failed for specific PUBLIC_URL
      }

      //*/
      let iconAndroid = <img className="SI-Icon SI-Icon-Null" src={picNull} alt="" />;
      if (this.props.data.plat_android)
          iconAndroid = <img className="SI-Icon" src={picAndr} alt="" />;

      let iconIOS = <img className="SI-Icon SI-Icon-Null" src={picNull} alt="" />;
      if (this.props.data.plat_ios)
          iconIOS = <img className="SI-Icon" src={picIOS} alt="" />;

      let iconPC = <img className="SI-Icon SI-Icon-Null" src={picNull} alt="" />;
      if (this.props.data.plat_pc)
          iconPC = <img className="SI-Icon" src={picPC} alt="" />;

      /*/
      let iconAndroid = "";
      if (this.props.data.plat_android)
        iconAndroid = <div className="SI-Icon  SI-Icon-Android" />;

      let iconIOS = "";
      if (this.props.data.plat_ios)
        iconIOS = <div className="SI-Icon  SI-Icon-IOS" />;

      let iconPC = "";
      if (this.props.data.plat_pc)
        iconPC = <div className="SI-Icon  SI-Icon-PC" />;
      //*/

      const Content = this.props.content; // Note: variable name _must_ start with a capital letter

      return <div className="ServiceInfo" >

        <table className="SI-table  debugFYJ3" >
          <tbody>

            <tr>
              <td className="SI-td-L  debugFYJ2"  align="left" valign="top" >
                <div className="SI-Pic  debugFYJ2" style={picSty} />
              </td>

              <td className="SI-td-R  debugFYJ2"  align="left" valign="top" >

                <div className="SI-Title-Area  debugFYJ" >

                  <div className="SI-Title  debugFYJ3" 
                      custom={svc.titleContextStyle(this.props.data)} dangerouslySetInnerHTML={{__html : this.props.data.title}}
                  />

                  {/*<div className="SI-SubTitle">{this.props.data.sub_title}</div>*/}

                  <div className="SI-Icon-Platform  debugFYJ2" >
                    {iconAndroid}
                    {iconIOS}
                    {iconPC}
                  </div>

                </div>


                {/*<div className="SI-Bar" />*/}
                <hr className="SI-Bar" />

                <Content data={this.props.data} />
                
              </td>
            </tr>

          </tbody>
        </table>

      </div>

    }

}

export default ServiceInfo;