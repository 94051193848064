import React from 'react';

import './ServiceFYJ.css';

function ServiceCapGame() {
  return (
    <div className="ServiceCapGame">
    </div>
  );
}

export default ServiceCapGame;
