import React from 'react';

import { HashRouter as Router ,Link } from "react-router-dom";

import './NewsdetailFooter.css';

class NewsdetailFooter extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      const cData = this.props.contentData;

      let btnPrev = "";
      if( cData.prevIndex > -1 )
      {
          btnPrev = <Link className="NewsdetailFooter-Btn-Prev" to={'/newsdetail/'+cData.prevIndex} ></Link>;
      }

      let btnTop = <Link className="NewsdetailFooter-Btn-Top" to="/news" ></Link>;

      let btnNext = "";
      if( cData.nextIndex > -1 )
      {
          btnNext = <Link className="NewsdetailFooter-Btn-Next" to={'/newsdetail/'+cData.nextIndex} ></Link>;
      }

      //let btnNext = "";

      return <div className="NewsdetailFooter  debugFYJ2">

          {btnPrev}
          {btnTop}
          {btnNext}

      </div>
        
    }

}

export default NewsdetailFooter;