import React from 'react';

import './ContactConfirm.css';

class ContactConfirm extends React.Component{

    constructor(props) {
        super(props);

        this.clickPrev = this.clickPrev.bind(this);
        this.clickNext = this.clickNext.bind(this);

        this.state = {
            data:{...props.data}
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    //////////////////////////
    
    clickPrev () {
        if( null != this.props.clickPrevDo && 'undefined' != typeof this.props.clickPrevDo )
          this.props.clickPrevDo( this.state.data );
    }

    clickNext () {
        if( null != this.props.clickNextDo && 'undefined' != typeof this.props.clickNextDo )
          this.props.clickNextDo( this.state.data );
    }

    //////////////////////////

    render() {

      return <div className="ContactConfirm" >

        <table className="ContactConfirm-table  debugFYJ2" >
            <tbody>
                <tr>
                    <td className="ContactConfirm_td_ttl" align="left" valign="top">
                    貴社名
                    </td>
                    <td className="ContactConfirm_td_ctt" align="left" valign="top">
                    {this.state.data.company_name}
                    </td>
                </tr>

                <tr>
                    <td className="ContactConfirm_td_ttl" align="left" valign="top">
                    お名前*
                    </td>
                    <td className="ContactConfirm_td_ctt" align="left" valign="top">
                    {this.state.data.agent_name}
                    </td>
                </tr>

                <tr>
                    <td className="ContactConfirm_td_ttl" align="left" valign="top">
                    電話番号
                    </td>
                    <td className="ContactConfirm_td_ctt" align="left" valign="top">
                    {this.state.data.tel}
                    </td>
                </tr>

                <tr>
                    <td className="ContactConfirm_td_ttl" align="left" valign="top">
                    返信先メールアドレス*
                    </td>
                    <td className="ContactConfirm_td_ctt" align="left" valign="top">
                    {this.state.data.mail}
                    </td>
                </tr>

                <tr>
                    <td className="ContactConfirm_td_ttl  ContactConfirm_td_ttl_mail_re" align="left" valign="top">
                        返信先メールアドレス*
                        <br /><small>（確認用）</small>
                    </td>
                    <td className="ContactConfirm_td_ctt" align="left" valign="top">
                        {this.state.data.mail_re}
                    </td>
                </tr>

                <tr>
                    <td className="ContactConfirm_td_ttl" align="left" valign="top">
                        お問い合わせ内容*
                    </td>
                    <td className="ContactConfirm_td_ctt  debugFYJ3" align="left" valign="top">
                        {this.state.data.context}
                    </td>
                </tr>

            </tbody>
        </table>

        <div  className="ContactCfm-Btns  debugFYJ2"  >
            <button className="ContactCfm-Btn  Contact-Btn-GoForm  debugFYJ3" onClick={this.clickPrev}> </button>
            <button className="ContactCfm-Btn  Contact-Btn-Send    debugFYJ3" onClick={this.clickNext}> </button>
        </div>

      </div>
        
    }

}

export default ContactConfirm;