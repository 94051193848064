import React from 'react';

import './HeaderBar.css';

function HeaderBar() {
  return (
    <div className="HeaderBar">
    </div>
  );
}

export default HeaderBar;
