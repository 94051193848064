
import * as util from "./Util.js";

function filterListSet( props_ , temp ) {

    util.debug("filterListSet temp.showRowNum=" + temp.showRowNum);
  
    let filterStart = (temp.nowPage - 1) * temp.showRowNum
    let filterEnd = temp.nowPage * temp.showRowNum
    temp.filteredList = props_.newsList.filter(function (el, index) {
      
        if ((index >= filterStart && index < filterEnd)) {
          return el
        }

        return null; // 190814
    })
    /*
    this.setState({
        filteredList: temp.filteredList
    })
    */

    temp.totalPage = Math.ceil(props_.newsList.length / temp.showRowNum)
    /*
    this.setState({
        totalPage: temp.totalPage
    })
    */

    checkPageShow( temp );

    calcPageIds( temp );

    util.debug("news temp.totalPage=" + temp.totalPage);
    //util.debug("totalPage_=" + totalPage_);
    util.debug("news temp.nowPage=" + temp.nowPage);
  
}



function checkPageShow( temp ) {

    util.debug("checkPageShow temp.totalPage=" + temp.totalPage);

    let hasPre_ = temp.totalPage > 0 && temp.nowPage - 1 > 0
    let hasNext_ = temp.totalPage > 0 && (temp.nowPage + 1 <= temp.totalPage)
    let isLast_ = temp.totalPage > 0 && (temp.nowPage !== temp.totalPage)

    temp.pageCheck = {
        hasPre: hasPre_,
        hasNext: hasNext_,
        isLast: isLast_
    }
    /*
    this.setState({
        pageCheck: {
            hasPre: hasPre_,
            hasNext: hasNext_,
            isLast: isLast_
        }
    })
    */
}


function calcPageIds( temp ) {
    //return [ -1 ,0 ,1 ,2 ,3 ,4 ,0 ,9999 ];

    let range = 3;
    let now = temp.nowPage;

    /*
    let pages = [];
    for(let i=0; i<temp.totalPage; i++ )
    {
      pages.push(i);
    }
    //*/

    let prev = [];
    let post = [];

    for(let i=now-range; i<=now; i++ )
    {
      //if( pages.have(i) )
      // Since pages is continuous int, so try check in other way ?
      if( 0 >= i )
        continue;

      prev.push(i);
    }

    util.log(prev);

    for(let i=now+1; i<=now+range; i++ )
    {
      // Since pages is continuous int, so try check in other way ?
      if( temp.totalPage < i )
        continue;

      post.push(i);
    }

    util.log(post);

    var all = [...prev , ...post];

    util.log(all);

    if( 1 !== prev[0] )
      all = [0, ...all];

    //if( temp.totalPage != post[post.length-1] )
    if( temp.totalPage !== all[all.length-1] )
      all = [...all ,0];

    all = [ -1 ,...all ,9999];

    temp.pageIds = all;


    util.debug("calcPageIds temp.pageIds");
    util.log(temp.pageIds);

}

function isHideNewIcon( data )
{
  if( 'undefined' === typeof data.hide_new_icon )
      return false; // default as shown

  return data.hide_new_icon;
}

export { filterListSet ,isHideNewIcon };