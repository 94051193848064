import React from 'react';

import { HashRouter as  Router ,Route ,Switch ,Link ,NavLink } from "react-router-dom";

import './TopFYJ.css';
//import sty from './TopFYJ.css';

import TopPics from './TopPics.js';
import TopButtons from './TopButtons.js';
import TopNews from './TopNews.js';
import TopBanners from './TopBanners.js';

class TopFYJ extends React.PureComponent{

  constructor(props) {
      super(props);
      this.state = {};
  }

  componentDidMount() {
    window.gtagPageview(this.props.location.pathname);

  }

  componentWillUnmount() {

  }

  render() {

    // location={this.props.location}  history={this.props.history} 

    return <div className="TopFYJ  debugFYJ" >
      <div className="TopFYJ-bg-upper  debugFYJ" >

        <TopPics match={this.props.match}   />
        {/*
        <Switch><Route  component={TopPics} /></Switch>
        */}

        <TopButtons />

      </div>
      <div className="TopFYJ-bg-bottom  debugFYJ2" >
        <TopNews />
        <TopBanners />
      </div>
    </div>
      
  }

}


export default TopFYJ;
