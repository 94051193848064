import React from 'react';

import { HashRouter as Router ,Link } from "react-router-dom";

import './ContactForm.css';

import * as util from "../../module/Util.js";

class ContactForm extends React.Component{

    constructor(props) {
        super(props);

        this.onChangeForm = this.onChangeForm.bind(this);

        this.clickNext = this.clickNext.bind(this);
        this.clickPrivacyOn = this.clickPrivacyOn.bind(this);
        this.clickPrivacyOff = this.clickPrivacyOff.bind(this);

        this.state = {

            isAllCheckDone : false,

            isCheckedPrivacy : false,

            data:{...props.data}

        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    /////////////////////////////////

    onChangeForm(event)
    {
        const tar = event.target;
        const cap = tar.name;
        const val = tar.value;

        let temp = {...this.state.data};
        temp[cap] = val;
        
        this.setState(
            {data: temp},
            ()=>{
                this.formCheck();
            }
        );
    }

    /////////////////////////////////

    clickNext () {

        if( ! this.state.isAllCheckDone )
            return;

        if( null != this.props.clickNextDo && 'undefined' != typeof this.props.clickNextDo )
          this.props.clickNextDo( this.state.data );
    }

    clickPrivacyOn()
    {
        //this.setState({isCheckedPrivacy: true});
        this.setState(
            {isCheckedPrivacy: true},
            ()=>{
                this.formCheck();
            }
        );
    }
    clickPrivacyOff()
    {
        //this.setState({isCheckedPrivacy: false});
        this.setState(
            {isCheckedPrivacy: false},
            ()=>{
                this.formCheck();
            }
        );
    }

    //////////////////////////////////

    formCheck()
    {
        let err = "";

        err = this.emptyCheck();
        if( "" !== err )
        {
            this.showError(err);
            this.setState({isAllCheckDone :false});
            return ; // #TEST hide
        }

        err = this.mailCheck();
        if( "" !== err )
        {
            this.showError(err);
            this.setState({isAllCheckDone :false});
            return ; // #TEST hide
        }

        if( ! this.state.isCheckedPrivacy )
        {
            this.showError("プライバシーポリシーに同意してください");
            this.setState({isAllCheckDone :false});
            return;
        }

        this.showError("");
        this.setState({isAllCheckDone :true});
    }

    emptyCheck() {

        const data = this.state.data;

        if( "" === data.agent_name )
            return "お名前を入力してください";

        if( "" === data.mail )
            return "メールアドレスを入力してください";

        if( "" === data.context )
            return "お問い合わせ内容を入力してください";

        return "";
    }

    mailCheck() {

        const data = this.state.data;
        if( data.mail !== data.mail_re )
            return "登録メールアドレスが一致しません";

        /*  move to emptyCheck()
        if( "" === data.mail )
            return "メールアドレスを入力してください";
        */

        if( ! util.validateEmail(data.mail) )
            return "不正なメール形式";

        return "";

    }

    showError (err_) {
        if( null != this.props.sendError && 'undefined' != typeof this.props.sendError )
          this.props.sendError( err_ );
    }

    /////////////////////////////////

    render() {

      let CheckboxPrivacy = <div className="Ctt-Checkbox-Privacy  Contact-Checkbox-Privacy  debugFYJ2"  onClick={this.clickPrivacyOn}  ></div>
      if( this.state.isCheckedPrivacy )
          CheckboxPrivacy = <div className="Ctt-Checkbox-Privacy  Contact-Checked-Privacy  debugFYJ2"  onClick={this.clickPrivacyOff}  />

      let BtnGoCfm = <div className="Ctt-Btn-GoCfm  Contact-Btn-GoCfm-Gray" />;
      if( this.state.isAllCheckDone )
          BtnGoCfm = <div className="Ctt-Btn-GoCfm  Contact-Btn-GoCfm" onClick={this.clickNext}> </div>;

      return <div className="ContactForm  debugFYJ" >

        <div className="Contact-Caution  debugFYJ2" >
            <div className="Contact-Caution-Txt  debugFYJ3" >
            弊社へのお問い合わせは、「プライバシーポリシー」に同意の上、
            <br />
            以下のフォームよりお送りください。
            <br />
            お問い合わせの内容によっては、返答までお時間を頂く場合や、
            <br />
            ご満足いただける回答を差し上げられない場合もございます。
            <br />
            また、内容によっては返答しかねる場合もございます。
            <br />
            ゲーム内容に関するお問い合わせには返信できません。
            <br />
            予めご了承ください。
            </div>
        </div>

        <div className="Contact-Must  debugFYJ3"  >
            ※印は必須項目となっておりますので、ご記入をお願いいたします。
        </div>

        <table className="Contact-table  debugFYJ2" >
            <tbody>
                <tr>
                    <td className="Contact_td_ttl" align="left" valign="top">
                    貴社名
                    </td>
                    <td className="Contact_td_ctt  debugFYJ2" align="left" valign="top">
                    <input className="Contact_td_input  debugFYJ3" type="text" onChange={this.onChangeForm}
                    value={this.state.data.company_name} 
                    name="company_name"
                    ></input>
                    </td>
                </tr>

                <tr>
                    <td className="Contact_td_ttl" align="left" valign="top">
                    お名前<span  className="Contact-RedDot"  >※</span>
                    </td>
                    <td className="Contact_td_ctt" align="left" valign="top">
                    <input className="Contact_td_input  debugFYJ3" type="text" onChange={this.onChangeForm}
                    value={this.state.data.agent_name} 
                    name="agent_name"
                    ></input>

                    </td>
                </tr>

                <tr>
                    <td className="Contact_td_ttl" align="left" valign="top">
                    電話番号
                    </td>
                    <td className="Contact_td_ctt  debugFYJ2" align="left" valign="top">
                    <input className="Contact_td_input  debugFYJ3" type="text" onChange={this.onChangeForm}
                    value={this.state.data.tel} 
                    name="tel"
                    ></input>
                    </td>
                </tr>

                <tr>
                    <td className="Contact_td_ttl" align="left" valign="top">
                    返信先メールアドレス<span  className="Contact-RedDot"  >※</span>
                    </td>
                    <td className="Contact_td_ctt" align="left" valign="top">
                    <input className="Contact_td_input  debugFYJ3" type="text" onChange={this.onChangeForm}
                    value={this.state.data.mail} 
                    name="mail"
                    ></input>
                    </td>
                </tr>

                <tr>
                    <td className="Contact_td_ttl  CttMr" align="left" valign="top">
                        <div className="Contact-Mail-Re-Root  debugFYJ2" >
                            返信先メールアドレス<span  className="Contact-RedDot"  >※</span>
                            <br /><span className="Contact-Mail-Re">（確認用）</span>
                        </div>
                    </td>
                    <td className="Contact_td_ctt" align="left" valign="top">
                        <input className="Contact_td_input  debugFYJ3" type="text" onChange={this.onChangeForm}
                        value={this.state.data.mail_re} 
                        name="mail_re"
                        ></input>
                    </td>
                </tr>


                <tr className="Contact_tr_context" >
                    <td className="Contact_td_ttl  debugFYJ" align="left" valign="top">
                    お問い合わせ内容<span  className="Contact-RedDot"  >※</span>
                    </td>
                    <td className="Contact_td_context  debugFYJ" align="left" valign="top">
                        <textarea className="Contact_textarea_context  debugFYJ3" type="text" onChange={this.onChangeForm}
                        value={this.state.data.context} 
                        name="context"
                        />
                    </td>
                </tr>

            </tbody>
        </table>

        <div className="Contact-Txt-Privacy  debugFYJ3" >個人情報の取扱い<span  className="Contact-RedDot"  >※</span></div>

        <div className="Contact-Btn-Privacy  debugFYJ3" >{CheckboxPrivacy}
            <Link to="/privacy"  target="_blank" rel="noopener noreferrer" 
                className="Contact-Link-Privacy  debugFYJ2"
            >「プライバシーポリシー」</Link>
            を必ずご確認いただき、同意の上お問い合わせください。
        </div>

        {/*<button className="Contact-Btn-GoCfm" onClick={this.clickNext}> </button>*/}
        {BtnGoCfm}

      </div>
        
    }

}

export default ContactForm;