import React from 'react';

import './ContactSent.css';

class ContactSent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="ContactSent" >

        <div className="ContactSent-Caution  debugFYJ2" >
            <div className="ContactSent-Caution-Txt  debugFYJ3" >
            お問い合わせの受付確認メールを送信しました。
            <br />
            受付確認のメールが届かない場合には、お客様にご入力頂いたメールアドレス、
            <br />
            もしくはメール受信設定に誤りがある可能性が考えられます。
            <br />
            上記内容をご確認の上、再度送信をお願いします。
            </div>
        </div>

      </div>
        
    }

}

export default ContactSent;