import React from 'react';

import { HashRouter as  Router ,Route ,Switch ,Link ,NavLink ,Redirect } from "react-router-dom";

import ServiceCapGame from './ServiceCapGame.js';
import ServiceCapDesign from './ServiceCapDesign.js';
import ServiceCapDev from './ServiceCapDev.js';

import ServiceTabs from './ServiceTabs.js';

import ServiceGame from './ServiceGame.js';
import ServiceDesign from './ServiceDesign.js';
import ServiceDev from './ServiceDev.js';

//import './ServiceFYJ.css';

class ServiceFYJ extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
      window.gtagPageview(this.props.location.pathname);
    }

    componentWillUnmount() {

    }

    render() {

      //const CapGame = <div className="ServiceGame-Cap" />;
      //const CapDev = <div className="ServiceDev-Cap" />;

      /*
      */

      return <div className="ServiceFYJ  debugFYJ" >

        <Switch>
            <Route path={`${this.props.match.path}/game`} component={ServiceCapGame} />
            <Route path={`${this.props.match.path}/design`} component={ServiceCapDesign} />
            <Route path={`${this.props.match.path}/dev`} component={ServiceCapDev} />
            <Route  component={ServiceCapGame} />
        </Switch>


        <ServiceTabs  match={this.props.match}  />

        <Switch>

            <Route exact path="/service/" render={() => (
                  <Redirect to="/service/game"/>
            )}/>

            <Route path={`${this.props.match.path}/game`} component={ServiceGame} />
            <Route path={`${this.props.match.path}/design`} component={ServiceDesign} />
            <Route path={`${this.props.match.path}/dev`} component={ServiceDev} />
            <Route  component={ServiceGame} />{/*default*/}
        </Switch>

      </div>
        
      }

}

export default ServiceFYJ;