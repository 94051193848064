import React from 'react';

import './ServiceGame.css';

import ServiceInfo from './ServiceInfo.js';

import ServiceInfoGame from './ServiceInfoGame.js';

import * as svc from '../../module/ServiceFYJ.js';

class ServiceGame extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="ServiceGame  " >

        {
            svc.serviceGame().map((el, index)=>
            {
                return (
                    <ServiceInfo 
                        key={index}
                        data={el}
                        content={ServiceInfoGame}
                    />
                )

            })
        }

      </div>
        
    }

}

export default ServiceGame;