import React from 'react';

import { HashRouter as  Router ,Route ,Switch ,Link ,NavLink } from "react-router-dom";

import './TopButtons.css';

class TopButtons extends React.PureComponent{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

      return <div className="TopButtons  debugFYJ" >

        <Link to="/company" className="TopButtons-Btn-Company" ></Link>
        <Link to="/recruit" className="TopButtons-Btn-Recruit" ></Link>
        <Link to="/service" className="TopButtons-Btn-Service" ></Link>

      </div>
        
    }

}

export default TopButtons;