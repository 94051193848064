//import Cookies from 'js-cookie';
//type define
export const TOGGLE_VOICE_SET = 'TOGGLE_VOICE_SET';
export const UPDATE_NEWS_LIST = 'UPDATE_NEWS_LIST';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const PAGE_MODE_CHANGE = 'PAGE_MODE_CHANGE';
export const NEWS_CONTENT_SHOW = 'NEWS_CONTENT_SHOW';
export const ADJUST_PAGE_WIDTH = 'ADJUST_PAGE_WIDTH';
export const UPDATE_RECRUIT = 'UPDATE_RECRUIT';

//define direct function call for change's new props
//ex : this.props.dispatch(updateNewsList([...]));
// export function updateNewsList(newList)
// {
//     return newList === undefined?{}:
//     {
//         type : UPDATE_NEWS_LIST,
//         newList
//     }
// }

const DefaultContentData = 
{
	type : {},
	title : '',
	content : '',
	prevIndex : -1,
	nextIndex : -1,
};

//basic state
const initState = 
{
    pageMode:'full',
    voiceSet : false, //(typeof Cookies.get('tbs_voiceon') !== "undefined"?(Cookies.get('tbs_voiceon') === '1') : true),
    newsList : [],
    leftBanner : {},
    bannerList : [],
    newsContent : DefaultContentData,
    recruitList : [],
    recruitNew : [],
    recruitCareer : [],
    /*
    moviveSetting:
    {
        width : 674,
        height : 379,
    },
    */
    newsContentSetting:
    {
        width : 986,
        height : 640,
    },
};

//handle function, use object for switch handle function
//ex : this.props.dispatch({type : stateHandlerClass.UPDATE_NEWS_LIST, newsList : [...]});
export default function stateHandler(nowState = initState, action)
{
    switch (action.type)
    {
        case TOGGLE_VOICE_SET :
        {
            let oldVoice = nowState.voiceSet;
            //Cookies.set('tbs_voiceon', !oldVoice?'1':'0');
            return {
                ...nowState,
                voiceSet : !oldVoice
            };
        }
        case UPDATE_NEWS_LIST : 
        {
            return{
                ...nowState,
                newsList : action.newsList
            };
        }
        case UPDATE_BANNER : 
        {
            return{
                ...nowState,
                leftBanner : action.leftBanner,
                bannerList : action.bannerList
            };
        }
        case PAGE_MODE_CHANGE : 
        {
            return {
                ...nowState,
                pageMode : action.pageMode
            }
        }
        case NEWS_CONTENT_SHOW:
        {
            return {
                ...nowState,
                newsContent : action.newsContent,
            }
        }
        case ADJUST_PAGE_WIDTH : 
        {
            return{
                ...nowState,
                //moviveSetting : action.moviveSetting,
                newsContentSetting : action.newsContentSetting,
            }
        }
        case UPDATE_RECRUIT : 
        {
            return{
                ...nowState,
                recruitList : action.recruitList,
                recruitNew : action.recruitNew,
                recruitCareer : action.recruitCareer
            };
        }
        default : 
            return nowState;
    }
}

